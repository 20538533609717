import React, { useState } from "react";
import "../../assets/css/Records.css";
import Record from "../Record/Record";
import { TextField, FormControl, InputAdornment } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
const Records = ({
  records,
  categories,
  onDelete,
  onUpdate,
  onVideoDelete,
  allLanguages,
  decreaseVideoCount,
  increaseVideoCount,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const styles = {
    position: "relative",
    margin: "0 0 10px 0",
    padding: "0 5px",
    // maxWidth: "200px"
  };

  return (
    <div id="records">
      <div className="container" style={{ marginLeft: "30%" }}>
        <FormControl
          style={{
            display: "flex",
            alignItems: "center",
            paddingTop: "15px",
            paddingBottom: "10px",
          }}
        >
          <TextField
            // id="outlined-basic"
            type="search"
            name="search"
            label="Search"
            variant="filled"
            placeholder="Enter video ID/title/category/tags"
            onChange={(e) => setSearchTerm(e.target.value)}
            color="primary"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
        {/* <j */}
        <div className="row justify-content-md-center">
          {records
            .filter((val) => {
              if (searchTerm === "") {
                return val;
              } else if (
                val.videoID.toLowerCase().includes(searchTerm.toLowerCase())
              ) {
                return val;
              } else if (
                val.title.toLowerCase().includes(searchTerm.toLowerCase())
              ) {
                return val;
              } else if (
                val.category.toLowerCase().includes(searchTerm.toLowerCase())
              ) {
                return val;
              } else if (val.tags.indexOf(searchTerm.toLowerCase()) >= 0) {
                return val;
              }
            })
            .map((record) => (
              <div key={record.id} className="col-md-3" style={styles}>
                <Record
                  record={record}
                  categories={categories}
                  onDelete={onDelete}
                  onUpdate={onUpdate}
                  onVideoDelete={onVideoDelete}
                  allLanguages={allLanguages}
                  decreaseVideoCount={decreaseVideoCount}
                  increaseVideoCount={increaseVideoCount}
                />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Records;
