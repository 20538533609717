import React, { useState, useEffect } from "react";
import {
  Alert,
  Form,
  FormGroup,
  Input,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { withFirebase } from "../../firebase";

const CreateMultipleRecordModal = ({
  isOpen,
  onClose,
  records,
  categories,
  addRecords,
  updateRecord,
}) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [file, setFile] = useState(null);

  categories = categories.map((cat) => cat.category);

  useEffect(() => {
    if (!!file) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [file]);

  const videoIDs = records.map((rec) => rec.videoID);

  const onChangeHandler = (e) => {
    console.log(e.target.files[0]);
    const newFile = e.target.files[0];
    setFile(newFile);
  };

  let fileReader;

  const readFile = () => {
    let content = fileReader.result;
    let contents = content.split("\n");
    let recs = contents
      .map((item) => {
        const [title, category, videoID, free, premium] = item.split("\t");
        const newObj = {
          title,
          category,
          videoID,
          free,
          premium,
        };
        if (!title || !category || !videoID || !(premium || free)) {
          // Add error
          return null;
        }
        if (!categories.includes(category)) {
          return null;
        }

        let index = videoIDs.indexOf(videoID);
        if (index !== -1) {
          updateRecord(records[index].id, newObj);
          return null;
        }
        // console.log(index, newObj);
        return newObj;
      })
      .filter((item) => item !== null);

    setLoading(true);
    addRecords(recs);
  };

  const upload = (e) => {
    e.preventDefault();
    fileReader = new FileReader();
    fileReader.onloadend = readFile;
    fileReader.readAsText(file);
  };

  return (
    <div>
      <Modal centered isOpen={isOpen} toggle={onClose}>
        <ModalHeader toggle={onClose}>Create New Record</ModalHeader>
        <ModalBody>
          {error && <Alert color="danger">{error}</Alert>}
          <Form>
            <FormGroup>
              <Input name="file" type="file" onChange={onChangeHandler} />
            </FormGroup>
            <Button color="primary" disabled={disabled} onClick={upload}>
              Upload
            </Button>
          </Form>
          <p className="h6 text-muted">{loading && `Please wait...`}</p>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default withFirebase(CreateMultipleRecordModal);
