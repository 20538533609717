import React from "react";
import { Modal, ModalBody, ModalHeader, Button, CardImg } from "reactstrap";

const DeleteModal = ({
  isOpen,
  onClose,
  record,
  deleteRecord,
  deleteVideoRecord,
}) => {
  const { videoID, title } = record;

  const deleteHandler = (e) => {
    e.preventDefault();
    deleteRecord(record);
    onClose();
  };
  const deleteVideoHandler = (e) => {
    e.preventDefault();
    deleteVideoRecord(record);
    onClose();
  };

  return (
    <div>
      <Modal centered isOpen={isOpen} toggle={onClose}>
        <ModalHeader
          toggle={onClose}
          close={
            <button
              style={{ width: "auto", padding: "3px 7px", background: "red" }}
              onClick={onClose}
            >
              x
            </button>
          }
        >
          Are you sure?
        </ModalHeader>
        <ModalBody>
          <div style={{ textAlign: "center" }}>
            {record.premiumVideo ? (
              <CardImg
                // style={{
                //   display: "block",
                //   margin: "0 auto",
                //   width: "80%",
                //   height: "150px",
                //   objectFit: "cover",
                // }}
                className="img-responsive"
                top
                width="100%"
                src={record.thumbnailURL}
                alt="thumbnail"
              />
            ) : (
              <CardImg
                // style={{
                //   display: "block",
                //   margin: "0 auto",
                //   width: "80%",
                //   height: "150px",
                //   objectFit: "cover",
                // }}
                className="img-responsive"
                top
                width="100%"
                src={`https://img.youtube.com/vi/${videoID}/hqdefault.jpg`}
                alt="thumbnail"
              />
            )}
            <p
              className="card-p r-title"
              style={{ fontSize: "1rem", fontWeight: "700" }}
            >
              {title}
            </p>
          </div>
          <div style={{ marginTop: "8px" }}>
            <Button
              color="primary"
              onClick={onClose}
              style={{ marginRight: "8px" }}
            >
              Cancel
            </Button>

            <Button
              color="danger"
              onClick={record.premiumVideo ? deleteVideoHandler : deleteHandler}
            >
              Delete
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default DeleteModal;
