import React, { useState, useEffect } from "react";
import "../../assets/css/AdminHome.css";
import { withFirebase } from "../../firebase/FirebaseContext";
import { Button } from "reactstrap";
import Skeleton from "../Skeleton/Skeleton";
import Records from "../Records/Records";
import SideBar from "../SideBar/SideBar";
import CreateRecordModal from "../CreateRecordModal/CreateRecordModal";
import CreateRecordVideo from "../CreateRecordModal/CreateRecordVideo";
import CreateMultipleRecordModal from "../CreateMultipleRecordModal/CreateMultipleRecordModal";

const AdminHome = ({ firebase, history }) => {
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalVideoOpen, setIsModalVideoOpen] = useState(false);
  const [isMultipleModalOpen, setIsMultipleModalOpen] = useState(false);

  const [records, setRecords] = useState([]);
  const [filterRecords, setFilterRecords] = useState([]);
  const [allFields, setAllFields] = useState([]);
  const [allLanguages, setAllLanguages] = useState([]);

  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [user, setUser] = useState("");

  useEffect(() => {
    firebase.auth.onAuthStateChanged(async (userAuth) => {
      if (!userAuth) {
        history.push("/admin/login");
      } else {
        setUser(userAuth);
      }
    });
  }, []);
  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    getRecords();
  }, []);
  useEffect(() => {
    // console.log("recs", filterRecords);
  }, [filterRecords]);

  useEffect(() => {
    let newRecords = records.filter((rec) => {
      let isCategory = true;
      if (selectedCategory !== "All" && selectedCategory !== "") {
        isCategory = rec.category === selectedCategory;
      }

      return isCategory;
    });
    // console.log("new recs", newRecords);
    setFilterRecords(newRecords);
  }, [selectedCategory]);

  const getRecords = async (category, audio, text) => {
    setLoading(true);
    try {
      let snapShot = await firebase.getRecords(category, audio, text);
      // console.log("snapshot", snapShot);
      const data = snapShot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      // console.log("data", data);
      setRecords(data);
      setFilterRecords(data);

      let dataField = [];
      let languages = [];

      // to fetch all the firelds and languages
      await data.forEach(async (queryResult) => {
        await Object.entries(queryResult).map((data) => {
          if (data[0] === "language") {
            languages.push(data[1]);
          }
          dataField.push(data[0]);
        });
      });
      setAllLanguages(Array.from(new Set(languages)));
      setAllFields(Array.from(new Set(dataField)));
    } catch (err) {
      console.log(err.message);
    }
    setLoading(false);
  };

  const getCategories = async () => {
    let snapShot = await firebase.getCategories();
    // console.log("snapshot", snapShot);
    const data = snapShot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    // console.log("categories", data);
    setCategories(data);
  };
  // const getFields = async () => {
  // let dataField = [];
  // let languages = [];
  // await filterRecords.forEach(async (queryResult) => {
  //   await Object.entries(queryResult).map((data) => {
  //     if (data[0] === "language") {
  //       languages.push(data[1]);
  //     }
  //     dataField.push(data[0]);
  //   });
  // });

  // console.log("ghghghg", Array.from(new Set(languages)));
  // console.log("f", Array.from(new Set(dataField)));
  // var nycRef = await firebase.getRecords();
  // nycRef.docs.forEach(async (queryResult) => {
  //   await Object.entries(queryResult.data()).map((data) => {
  //     if (data[0] == "language") {
  //       languages.push(data[1]);
  //     }
  //     dataField.push(data[0]);
  //   });
  // });

  // setAllLanguages(Array.from(new Set(languages)));
  // setAllFields(Array.from(new Set(dataField)));
  // };

  const addRecord = async (record) => {
    record.timestamp = firebase.fromSecondsToTimestamp();
    const doc = await firebase.addRecord({ ...record, UserID: user.uid });
    let newRecord = {
      ...record,
      id: doc.id,
    };
    let newRecords = [...records, newRecord];
    newRecords.sort((a, b) => (a.timestamp <= b.timestamp ? -1 : 1));
    setRecords(newRecords);

    let isCategory = true;

    if (selectedCategory !== "All" && selectedCategory !== "") {
      isCategory = newRecord.category === selectedCategory;
    }

    if (isCategory) {
      setFilterRecords([newRecord, ...filterRecords]);
    }
    setIsModalOpen(false);
    setIsModalVideoOpen(false);
  };

  const addMultipleRecord = async (recs) => {
    // recs.forEach(async record => await addRecord(record));
    let all = recs.map((record) => {
      record.timestamp = firebase.fromSecondsToTimestamp();
      return firebase.addRecord(record);
    });

    let ids = await Promise.all(all);
    let newRecs = recs.map((record, index) => {
      let newRecord = {
        ...record,
        id: ids[index].id,
      };
      return newRecord;
    });
    let updatedRecords = [...records, ...newRecs];
    updatedRecords.sort((a, b) => (a.timestamp <= b.timestamp ? -1 : 1));
    setRecords(updatedRecords);
    setIsMultipleModalOpen(false);
  };

  const deleteRecord = async (record) => {
    await firebase.deleteRecord(record);
    const newRecords = records.filter((rec) => rec.id !== record.id);
    setRecords(newRecords);
    const newFilterRecords = filterRecords.filter(
      (rec) => rec.id !== record.id
    );
    setFilterRecords(newFilterRecords);
  };
  const onVideoDelete = async (record) => {
    await firebase.deleteVideoRecord(record);
    const newRecords = records.filter((rec) => rec.id !== record.id);
    setRecords(newRecords);
    const newFilterRecords = filterRecords.filter(
      (rec) => rec.id !== record.id
    );
    setFilterRecords(newFilterRecords);
  };

  const updateRecord = async (id, record) => {
    const { timestamp, ...rest } = record;

    const newRecord = {
      ...rest,
      timestamp: firebase.fromSecondsToTimestamp(timestamp),
    };
    await firebase.updateRecord(id, newRecord);
    newRecord.id = id;

    const newRecords = records.map((rec) => (rec.id === id ? newRecord : rec));
    newRecords.sort((a, b) => (a.timestamp <= b.timestamp ? -1 : 1));
    setRecords(newRecords);
    const newFilterRecords = filterRecords.map((rec) =>
      rec.id === id ? newRecord : rec
    );
    newFilterRecords.sort((a, b) => (a.timestamp >= b.timestamp ? -1 : 1));
    setFilterRecords(newFilterRecords);
  };

  return (
    <div id="main-div">
      {isModalOpen && (
        <CreateRecordModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          addFieldInAllDocument={firebase.addFieldInAllDocument}
          allFields={allFields}
          allLanguages={allLanguages}
          addRecord={addRecord}
          isVideoId={firebase.isVideoId}
          categories={categories}
        />
      )}
      {isMultipleModalOpen && (
        <CreateMultipleRecordModal
          isOpen={isMultipleModalOpen}
          onClose={() => setIsMultipleModalOpen(false)}
          addRecords={addMultipleRecord}
          updateRecord={updateRecord}
          categories={categories}
          records={filterRecords}
        />
      )}
      {isModalVideoOpen && (
        <CreateRecordVideo
          isOpen={isModalVideoOpen}
          onClose={() => setIsModalVideoOpen(false)}
          addRecord={addRecord}
          categories={categories}
          UserID={user.uid}
          firebaseStorage={firebase.getUserProfileImage()}
        />
      )}
      <div id="top-btn-div">
        <div
          id="btn-cat"
          onClick={() => history.push("/admin/content/categories")}
        >
          Categories
        </div>
      </div>
      <button
        id="add-video-btn"
        color="primary"
        onClick={() => setIsModalVideoOpen(true)}
      >
        Video Upload
      </button>
      <button id="add-btn" color="primary" onClick={() => setIsModalOpen(true)}>
        Youtube Video
      </button>
      {/* <button
        id="add-multiple-btn"
        color="primary"
        onClick={() => setIsMultipleModalOpen(true)}
      >
        *
      </button> */}
      <button id="refresh-btn" color="primary" onClick={() => getRecords()}>
        <i className="fa fa-refresh" aria-hidden="true" />
      </button>

      <Button
        id="btn-signout"
        color="primary"
        onClick={() => {
          firebase.signOutUser();
          history.push("/admin/login");
        }}
      >
        Sign Out
      </Button>
      {loading ? (
        <Skeleton />
      ) : (
        <div id="main-content">
          <SideBar
            categories={categories}
            selectedCategory={selectedCategory}
            selectCategory={setSelectedCategory}
          />
          <Records
            records={filterRecords}
            categories={categories}
            allLanguages={allLanguages}
            decreaseVideoCount={firebase.decreaseVideoCount}
            increaseVideoCount={firebase.increaseVideoCount}
            onDelete={deleteRecord}
            onUpdate={updateRecord}
            onVideoDelete={onVideoDelete}
          />
        </div>
      )}
    </div>
  );
};

export default withFirebase(AdminHome);
