import React, { useState } from "react";
import {
  // Form,
  // FormGroup,
  // Input,
  // Label,
  // Button,
  // Alert,
  Spinner,
} from "reactstrap";
import { withRouter, Link } from "react-router-dom";
import { withFirebase } from "../../firebase";
import "react-phone-number-input/style.css";
// import { faEye } from "@fortawesome/free-solid-svg-icons";
// import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isValidPhoneNumber } from "react-phone-number-input";
import PhoneInput from "react-phone-input-2";

import "react-phone-input-2/lib/material.css";
import {
  Avatar,
  Grid,
  Paper,
  TextField,
  Button,
  Typography,
  FormControl,
  IconButton,
  OutlinedInput,
  InputAdornment,
  InputLabel,
  FormHelperText,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { pink } from "@material-ui/core/colors";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import LockOpenOutlinedIcon from "@material-ui/icons/LockOpenOutlined";

const useStyles = makeStyles((theme) => ({
  root: {
    // display: "flex",
    // flexWrap: "wrap",
  },
  margin: {
    marginRight: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    // width: "25ch",
  },
  pink: {
    color: theme.palette.getContrastText(pink[500]),
    backgroundColor: pink[500],
  },
}));

const UserSignUp = ({ firebase, history }) => {
  const classes = useStyles();
  const [signingIn, setSigningIn] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [number, setNumber] = useState("");
  const [error, setError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [nameError, setNameError] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  // useEffect(() => {
  //   if (!!firebase.user) {
  //     history.push("/home");
  //   }
  // }, []);

  // useEffect(() => {
  //   if (!!email && !!password) {
  //     setDisabled(false);
  //   } else {
  //     setDisabled(true);
  //   }
  // }, [email, password]);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSignUpButton = async () => {
    if (firstName.length === 0) {
      setPasswordError("");
      setError("");
      return setNameError("Please enter valid name");
    }
    if (lastName.length === 0) {
      return setNameError("Please enter valid name");
    }
    if (!new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(email)) {
      setNameError("");
      setPasswordError("");
      setError("");
      return setEmailError("Enter valid email");
    }
    if (password.length < 6) {
      setEmailError("");
      setNameError("");
      setError("");
      setPasswordError("Password must be at least six characters");
      return;
    }
    if (password !== confirmPassword) {
      return setPasswordError("Password do not match.");
    }
    if (!isValidPhoneNumber("+" + number)) {
      setPasswordError("");
      setEmailError("");
      setNameError("");
      return setError("Please enter a valid number");
    }

    setDisabled(true);
    setSigningIn(true);
    const res = await firebase.createUser(email, password);
    const newRecord = {
      fName: firstName,
      lName: lastName,
      phone: "+" + number,
      email: email,
    };
    // console.log("res from fb instance", res);
    const { success, error } = res;
    if (success) {
      firebase.addUserRecord(newRecord);
      history.push("/");
    } else {
      setError(error);
      setSigningIn(false);
      setDisabled(false);
    }
  };

  return (
    <div>
      <Grid spacing={1} className="loginGrid">
        <Paper elevation={10} className="loginForm">
          <Grid align="center">
            <Avatar className={classes.pink}>
              <LockOpenOutlinedIcon />
            </Avatar>
            <h1>Sign Up</h1>
          </Grid>
          {error && (
            <Alert variant="filled" severity="error">
              {error}
            </Alert>
          )}
          <FormControl
            id="signUpName"
            className={clsx(classes.margin, classes.withoutLabel)}
          >
            <TextField
              error={nameError}
              helperText={nameError}
              autoFocus
              id="outlined-secondary"
              label="Enter First Name"
              type="text"
              variant="outlined"
              placeholder="First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
            />
          </FormControl>
          <FormControl id="signUpName" className={clsx(classes.withoutLabel)}>
            <TextField
              error={nameError}
              helperText={nameError}
              id="outlined-secondary"
              label="Enter Last Name"
              type="text"
              variant="outlined"
              placeholder="Last Name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
            />
          </FormControl>
          <FormControl className={clsx(classes.withoutLabel)} fullWidth>
            <TextField
              error={emailError}
              helperText={emailError}
              id="outlined-secondary"
              label="Enter Email"
              type="email"
              variant="outlined"
              placeholder="example@mail.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </FormControl>

          <FormControl className={clsx(classes.withoutLabel)} fullWidth>
            <PhoneInput
              enableSearch
              autocompleteSearch
              country={"in"}
              value={number}
              onChange={setNumber}
              required
            />
          </FormControl>
          <FormControl
            className={clsx(classes.withoutLabel)}
            variant="outlined"
            required
            fullWidth
          >
            <InputLabel htmlFor="outlined-adornment-password">
              Password
            </InputLabel>

            <OutlinedInput
              error={passwordError}
              id="outlined-adornment-password"
              placeholder="Enter Password"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleShowPassword}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              labelWidth={80}
            />
            {passwordError && (
              <FormHelperText style={{ color: "red" }}>
                {passwordError}
              </FormHelperText>
            )}
          </FormControl>

          <FormControl
            className={clsx(classes.withoutLabel)}
            variant="outlined"
            required
            fullWidth
          >
            <InputLabel htmlFor="outlined-adornment-password">
              Confirm Password
            </InputLabel>

            <OutlinedInput
              error={passwordError}
              id="outlined-adornment-password"
              placeholder="Enter Password"
              type={showPassword ? "text" : "password"}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              labelWidth={150}
            />
            {passwordError && (
              <FormHelperText style={{ color: "red" }}>
                {passwordError}
              </FormHelperText>
            )}
          </FormControl>

          <FormControl className={clsx(classes.withoutLabel)} fullWidth>
            {signingIn ? (
              <Spinner color="success" />
            ) : (
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={disabled}
                onClick={handleSignUpButton}
              >
                Create Account
              </Button>
            )}
          </FormControl>

          <Typography className={clsx(classes.withoutLabel)} align="right">
            Already have an Account ? <Link to="/login">Login</Link>
          </Typography>
        </Paper>
      </Grid>
    </div>
    // <div>
    //   <section className="login">
    //     <div className="loginContainer">
    //       <h1>Sign Up</h1>
    //       <label>First Name</label>
    //       <input
    //         type="text"
    //         autoFocus
    //         placeholder="Enter First Name"
    //         required
    //         value={firstName}
    //         onChange={(e) => setFirstName(e.target.value)}
    //       />
    //       <label>Last Name</label>
    //       <input
    //         type="text"
    //         placeholder="Enter Last Name"
    //         required
    //         value={lastName}
    //         onChange={(e) => setLastName(e.target.value)}
    //       />
    //       <label>Email</label>
    //       <input
    //         type="email"
    //         placeholder="Email"
    //         required
    //         value={email}
    //         onChange={(e) => setEmail(e.target.value)}
    //       />
    // <label>Mobile</label>
    // <PhoneInput
    //   countryCallingCodeEditable
    //   defaultCountry="IN"
    //   placeholder="Enter Number"
    //   value={number}
    //   required
    //   onChange={setNumber}
    // />
    //       <label>Password</label>
    //       <input
    //         type={showPassword ? "password" : "text"}
    //         placeholder="Password"
    //         required
    //         value={password}
    //         onChange={(e) => setPassword(e.target.value)}
    //       />
    //       <div className="passwordContainer">
    //         <label>Confirm Password</label>
    //         <input
    //           type={showPassword ? "password" : "text"}
    //           placeholder="Confirm Password"
    //           required
    //           value={confirmPassword}
    //           onChange={(e) => setConfirmPassword(e.target.value)}
    //         />
    //         <span id="showpassword" onClick={handleShowPassword}>
    //           {showPassword ? (
    //             <FontAwesomeIcon icon={faEye} />
    //           ) : (
    //             <FontAwesomeIcon icon={faEyeSlash} />
    //           )}
    //         </span>
    //       </div>
    //       <p className="errorMsg">{error}</p>
    //       <div className="btnContainer">
    //         {signingIn ? (
    //           <Spinner color="success" />
    //         ) : (
    //           <button disabled={disabled} onClick={handleSignUpButton}>
    //             Sign Up
    //           </button>
    //         )}
    //         <p>
    //           Already have an Account ?
    //           <span>
    //             <Link to="/login">Login</Link>
    //           </span>
    //         </p>
    //         {/* {signingIn && <p className="h6">Please wait...</p>} */}
    //       </div>
    //     </div>
    //   </section>
    // </div>
  );
};
const Component = withFirebase(UserSignUp);

export default withRouter(Component);
