import React, { useState } from "react";
import { Card, CardImg } from "reactstrap";
import VideoPlayer from "../VideoPlayer/VideoPlayer";
import Videos from "./Videos";
import { PlayCircleOutlineOutlined } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { CardActionArea } from "@material-ui/core";
const UserRecord = ({
  record,
  records,
  categories,
  firebase,
  history,
  marginClass,
}) => {
  const { title, videoID, timestamp, thumbnailURL, duration } = record;

  const [isPlayerOpen, setIsPlayerOpen] = useState(false);

  const clickHandler = () => {
    setIsPlayerOpen(true);
  };

  const ellipsize = (str, limit) => {
    if (str.length > limit) {
      return `${str.slice(0, limit)}...`;
    }
    return str;
  };

  // const convertDate = (ts) => {
  //   const { nanoseconds: ns, seconds: s } = ts;
  //   let date = new Date(s * 1000).toString().split(" ");
  //   let nDate = `${date[1]} ${date[2]}, ${date[3]}`;
  //   return nDate;
  // };

  return (
    <div className="user-record">
      {/* {isPlayerOpen && (
        <VideoPlayer
          isOpen={isPlayerOpen}
          onClose={() => setIsPlayerOpen(false)}
          record={record}
        />
      )} */}
      <Link
        to={{
          pathname: `/video/${record.title}`,
          video: record,
          firebase: firebase,
          history: history,
          videos: records,
          // categoryName: category.category,
          // categoryImage: category.imageUrl,
          categories: categories,
        }}
        params={{ video: record.title }}
        id="record-more-button"
      >
        <Card title={title} className={`cardView ${marginClass}`}>
          {record.premiumVideo ? (
            <CardActionArea style={{ outline: "none" }}>
              <PlayCircleOutlineOutlined id="video-play-icon" />
              <div style={{ display: "flex", flexDirection: "column" }}>
                <CardImg
                  className="card-image"
                  onClick={clickHandler}
                  src={thumbnailURL}
                  alt="thumbnail"
                  title={title}
                />
                <img
                  alt="premium"
                  style={{
                    position: "absolute",
                    top: "5px",
                    right: "10px",
                  }}
                  src="https://www.flaticon.com/svg/static/icons/svg/1458/1458256.svg"
                  width="20px"
                />

                <p className="card-title">{ellipsize(title, 28)}</p>
                {/* <small className="text-muted card-p">
              {convertDate(timestamp)}
            </small>
          */}
              </div>
            </CardActionArea>
          ) : (
            <CardActionArea style={{ outline: "none", display: "flex" }}>
              <PlayCircleOutlineOutlined id="video-play-icon" />
              <CardImg
                onClick={clickHandler}
                src={`https://img.youtube.com/vi/${videoID}/maxresdefault.jpg`}
                alt="thumbnail"
              />
              <p className="card-title">{ellipsize(title, 28)}</p>
              <p className="card-duration">{duration}</p>
            </CardActionArea>
          )}
        </Card>
      </Link>
    </div>
  );
};

export default UserRecord;
