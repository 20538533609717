import React, { useState, useEffect } from "react";
import { withFirebase } from "../../firebase/FirebaseContext";
import { Link, useHistory } from "react-router-dom";
import {
  Avatar,
  Grid,
  Paper,
  TextField,
  Button,
  Typography,
  FormControl,
  IconButton,
  MenuItem,
  Select,
} from "@material-ui/core";
import "../../assets/css/NavBar.css";
import { Link as RouterLink } from "react-router-dom";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { makeStyles, useTheme, withTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
// import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
// import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    paddingRight: "79px",
    paddingLeft: "118px",
    background: "linear-gradient(to top, rgba(255, 0, 0, 0) 0%, rgb(0, 0, 0))",
    // backgroundColor: "transparent",
    boxShadow: "none",
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    "@media (max-width: 900px)": {
      paddingLeft: 0,
    },
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "black",
    color: "white",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  drawerContainer: {
    padding: "20px 30px",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 12,
    color: "white",
  },
}));
const NavBar = ({ firebase, records, categories }) => {
  let history = useHistory();
  const [navigationLevel, setNavigationLevel] = useState("Category");
  const {
    drawerContainer,
    toolbar,
    drawerPaper,
    appBar,
    appBarShift,
    root,
    formControl,
    drawerHeader,
  } = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchResult, setSearchResult] = useState([]);

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });
  const { mobileView, drawerOpen } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 950
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  const explifiedLogo = (
    <Typography variant='h6' component='h1' className='website-title'>
      <Link to='/'>Explified</Link>
    </Typography>
  );
  const handleSearchClick = async () => {
    if (searchText) {
      let searchArray = [];
      records.map((record) => {
        if (
          record.title.toLowerCase().includes(searchText.toLowerCase()) ||
          record.category.toLowerCase().includes(searchText.toLowerCase()) ||
          record.tags.includes(searchText.toLowerCase())
        ) {
          searchArray.push(record);
        }
      });
      await setSearchResult(searchArray);
      history.replace({
        pathname: `/search/${searchArray.length}`,
        searchArray: searchArray,
        videos: records,
        categories: categories,
        searchText: searchText,
        firebase: firebase,
        history: history,
        search: searchText
      });
    }
  };
  // const ellipsize = (str, limit) => {
  //   if (str.length > limit) {
  //     return `${str.slice(0, limit)}...`;
  //   }
  //   return str;
  // };
  const displayDesktop = () => {
    return (
      <Toolbar className={toolbar}>
        {explifiedLogo}
        <div>
          <div className='search-container'>
            <input
              id='search-text'
              type='search'
              placeholder='Search...'
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyPress={(event)=> event.key === "Enter" && handleSearchClick()}
            ></input>
            <label
              htmlFor='search-text'
              onClick={handleSearchClick}
              className='search-btn'
            >
              <FontAwesomeIcon icon={faSearch} />
            </label>
            {/* {searchResult.length !== 0 && (
              <div className='search-result-container'>
                {searchResult.map((searchData) => (
                  <Link
                    key={searchData.videoID}
                    to={{
                      pathname: `/video/${searchData.title}`,
                      video: searchData,
                      firebase: firebase,
                      history: history,
                      videos: records,
                      categories: categories,
                    }}
                    params={{ video: searchData.title }}
                    id='search-data-link'
                  >
                    <div className='search-result'>
                      <div className='left'>
                        <img
                          src={`https://img.youtube.com/vi/${searchData.videoID}/maxresdefault.jpg`}
                          alt='thumbnail'
                        />
                      </div>
                      <div className='right'>
                        <div className='upper'>
                          <p>{ellipsize(searchData.title, 20)}</p>
                        </div>
                        <div className='bottom'>
                          <span>{searchData.category}</span>
                          <span>{searchData.duration}</span>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            )} */}
          </div>
          <a className='navvidbtn' href='https://explified.com/video-school/'>
            Video School
          </a>
          <a className='navvidbtn' href='https://explified.com/video-making/'>
            Video Making
          </a>
          {getMenuButtons()}
        </div>
      </Toolbar>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));
    return (
      <Toolbar>
        <IconButton
          {...{
            edge: "start",
            color: "inherit",
            "aria-label": "menu",
            "aria-haspopup": "true",
            onClick: handleDrawerOpen,
          }}
        >
          <MenuIcon />
        </IconButton>
        <Drawer
          {...{
            anchor: "left",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
          classes={{
            paper: drawerPaper,
          }}
        >
          <div className={drawerHeader}>
            <Typography>Explified</Typography>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon color='primary' />
              ) : (
                <ChevronRightIcon color='primary' />
              )}
            </IconButton>
          </div>
          <Divider />

          <div className={drawerContainer}>{getDrawerChoices()}</div>
        </Drawer>
        <div>{explifiedLogo}</div>
      </Toolbar>
    );
  };

  const getDrawerChoices = () => {
    return (
      <>
        <a className='navvidbtn' href='https://explified.com/video-school/'>
          Video School
        </a>
        <br />
        <a className='navvidbtn' href='https://explified.com/video-making/'>
          Video Making
        </a>
        <FormControl>
          <Select
            className={formControl}
            value={navigationLevel}
            onChange={(event) => setNavigationLevel(event.target.value)}
          >
            <MenuItem value={navigationLevel} hidden disabled>
              {navigationLevel}
            </MenuItem>
            {categories.map(({ videoCount, category, imageUrl }) => {
              if (videoCount !== 0) {
                return (
                  <MenuItem
                    {...{
                      component: RouterLink,
                      to: {
                        pathname: `/category/${category}`,
                        videos: records,
                        firebase: firebase,
                        history: history,
                        categoryName: category,
                        categoryImage: imageUrl,
                        categories: categories,
                      },
                      state: "hello",
                      color: "inherit",
                      // style: { textTransform: "none" },
                      key: category,
                    }}
                    value={category}
                    key={category}
                  >
                    {category}
                  </MenuItem>
                );
              }
            })}
          </Select>
        </FormControl>
      </>
    );
    // return headersData.map(({ label, href }) => {
    //   return (
    //     <Button
    //       {...{
    //         component: RouterLink,
    //         to: href,
    //         color: "inherit",
    //         // style: { textTransform: "none" },
    //         key: label,
    //       }}
    //     >
    //       <MenuItem className="drawerButton">{label}</MenuItem>
    //     </Button>
    //   );
    // });
  };

  const getMenuButtons = () => {
    return (
      <FormControl>
        <Select
          className={formControl}
          value={navigationLevel}
          onChange={(event) => setNavigationLevel(event.target.value)}
        >
          <MenuItem value='Category' selected hidden disabled>
            Category
          </MenuItem>
          {categories.map(({ videoCount, category, imageUrl }) => {
            if (videoCount !== 0) {
              return (
                <MenuItem
                  {...{
                    component: RouterLink,
                    to: {
                      pathname: `/category/${category}`,
                      videos: records,
                      firebase: firebase,
                      history: history,
                      categoryName: category,
                      categoryImage: imageUrl,
                      categories: categories,
                    },
                    color: "inherit",
                    // style: { textTransform: "none" },
                    key: category,
                  }}
                  value={category}
                  key={category}
                >
                  {category}
                </MenuItem>
              );
            }
          })}
        </Select>
      </FormControl>
    );
  };
  // const handleDrawerOpen = () => {
  //   setOpen(true);
  // };

  // const handleDrawerClose = () => {
  //   setOpen(false);
  // };
  const [userDetails, setUserDetails] = useState([]);
  const [image, setImage] = useState("");
  const [user, setUser] = useState("");

  // useEffect(() => {
  //   firebase.auth.onAuthStateChanged(async (userAuth) => {
  //     if (userAuth) {
  //       getUserProfileImage();
  //       getUserProfileDetail();
  //       setUser(userAuth);
  //     } else {
  //       history.push("/login");
  //     }
  //   });
  // }, [user]);

  const getUserProfileDetail = async () => {
    let snapShot = await firebase.getUserProfileDetails();
    const data = snapShot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    setUserDetails(data);
  };
  const getUserProfileImage = () => {
    firebase
      .getUserProfileImage()
      .ref(`users/${user.uid}/profile.jpg`)
      .getDownloadURL()
      .then((url) => {
        setImage(url);
      })
      .catch((error) => {
        // console.log(error);
        setImage(
          "https://i.pinimg.com/originals/51/f6/fb/51f6fb256629fc755b8870c801092942.png"
        );
      });
  };

  return (
    <div className={root}>
      <CssBaseline />
      <AppBar
        position='fixed'
        className={clsx(appBar, {
          [appBarShift]: open,
        })}
      >
        {mobileView ? displayMobile() : displayDesktop()}
      </AppBar>
    </div>
  );
  // return (
  //   <div className="navbar">
  //     <nav>
  //       <Link to="/">Explified</Link>
  //       <div id="user-name">
  //         {userDetails.map(({ id, fName, lName, Url }) => (
  //           <div key={id}>
  //             {id === user.uid ? (
  //               <div>
  //                 <span className="name">Welcome {fName + " " + lName},</span>
  //                 <span id="user-avatar">
  //                   <Link to="/profile">
  //                     <Avatar
  //                       id="avatar"
  //                       src={Url || image}
  //                       alt="avatar"
  //                     ></Avatar>
  //                     {/* <img id="avatar" src={Url || image} alt="avatar" /> */}
  //                   </Link>
  //                 </span>
  //               </div>
  //             ) : (
  //               <p></p>
  //             )}
  //           </div>
  //         ))}
  //       </div>
  //       {/* {loggedIn && <div>
  //           <span className="name">Hello User!,</span>
  //           <span id="user-avatar">
  //             <Link to="/profile">
  //               <img id="avatar" src={image} alt="avatar" />
  //             </Link>
  //           </span>
  //         </div>} */}
  //       <button
  //         onClick={() => {
  //           firebase.signOutUser();
  //           history.push("/login");
  //         }}
  //       >
  //         Logout
  //       </button>
  //     </nav>
  //   </div>
  // );
};

export default withFirebase(NavBar);
