import React, { useState, useEffect } from "react";
import Footer from "./Footer";
import NavBar from "./NavBar";

import UserRecord from "../Home/UserRecord";
import "../../assets/css/AllCategoryVideos.css";

const AllCategoryVideos = (props) => {
  const [videos, setVideos] = useState(
    props.location.videos || JSON.parse(localStorage.getItem("videos-list"))
  );
  const [categories, setCategories] = useState(
    props.location.categories ||
      JSON.parse(localStorage.getItem("category-list"))
  );
  const [categoryName, setCategoryName] = useState(
    props.location.categoryName ||
      JSON.parse(localStorage.getItem("category-name"))
  );
  const [categoryImage, setCategoryImage] = useState(
    props.location.categoryImage ||
      JSON.parse(localStorage.getItem("category-image"))
  );
  const [history] = useState(props);
  const [firebase] = useState(props.location.firebase);
  // useEffect(() => {
  //   localStorage.setItem("videos-list", JSON.stringify(videos));
  //   localStorage.setItem("category-name", JSON.stringify(categoryName));
  //   localStorage.setItem("category-image", JSON.stringify(categoryImage));
  //   localStorage.setItem("category-list", JSON.stringify(categories));
  // }, [categoryImage]);

  useEffect(() => {
    if (categoryName !== props.match.params.category) {
      window.scrollTo(0, 0);
      setVideos(props.history.location.videos);
      setCategoryImage(props.history.location.categoryImage);
      setCategoryName(props.history.location.categoryName);
    } else {
      // setCategories(categories);
      // setVideos(videos);
      // let videos = localStorage.getItem("videos-list");
      // let categoryName = localStorage.getItem("category-name");
      // let categoryImage = localStorage.getItem("category-image");
      // console.log("sdsdsdsd", JSON.parse(videos));
      // if (videos) {
      //   setVideos(JSON.parse(videos));
      // }
      // if (categoryName) {
      //   setCategoryName(JSON.parse(categoryName));
      // }
      // if (categoryImage) {
      //   setCategoryImage(JSON.parse(categoryImage));
      // }
    }
  }, [props.match.params.category]);

  useEffect(() => {
    const { history } = props;
    window.addEventListener("popstate", (categ) => {
      // let videos = localStorage.getItem("videos-list");
      // let categoryName = localStorage.getItem("category-name");
      // let categoryImage = localStorage.getItem("category-image");
      // console.log("after", JSON.parse(categoryName));
      // setVideos(JSON.parse(videos));
      // setCategoryName(JSON.parse(categoryName));
      // setCategoryImage(JSON.parse(categoryImage));
      // console.log("sasasas", categoryName);
      history.push("/");
      return;
    });
    // eslint-disable-next-line
  }, []);
  return (
    <div key={props.location.key} className="AllCategoryVideos">
      <NavBar
        firebase={firebase}
        history={history}
        records={videos}
        categories={categories}
      />
      <div
        style={{ backgroundImage: `url(${categoryImage})` }}
        className="catImgCard"
      >
        <div id="cat-name"> {categoryName}</div>
      </div>

      <div className="main-video-container">
        <h2 id="cat-img-name">Videos</h2>
        <div className="all-video-container">
          {videos.map(
            (video) =>
              video.category === categoryName && (
                <div className="videog" key={video.videoID}>
                  <UserRecord
                    record={video}
                    records={videos}
                    categories={categories}
                    marginClass="m0m0"
                  />
                </div>
              )
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default AllCategoryVideos;
