import React, { useState, useEffect } from "react";
import Footer from "./Footer";
import NavBar from "./NavBar";
import { Language, Info, Theaters } from "@material-ui/icons";
import UserRecord from "../Home/UserRecord";
import Skeleton from "@material-ui/lab/Skeleton";
import ReactPlayer from "react-player";
import "../../assets/css/Videos.css";

const Videos = (props) => {
  const [loading, setLoading] = useState(true);
  const [videos, setVideos] = useState(props.location.videos);
  const [video, setVideo] = useState(props.location.video);
  const [categories, setCategories] = useState(props.location.categories);
  // const [categoryName, setCategoryName] = useState(props.location.categoryName);
  // const [categoryImage, setCategoryImage] = useState(
  //   props.location.categoryImage
  // );
  const [history] = useState(props.location.history);
  const [firebase] = useState(props.location.firebase);
  useEffect(() => {
    // if (categoryName !== props.match.params.category)
    {
      window.scrollTo(0, 0);
      // setVideos(props.location.videos);
      setLoading(true);
      setVideo(props.location.video);
      // setCategoryImage(props.location.categoryImage);
      // setCategoryName(props.location.categoryName);
    }
    //  else {
    //   setCategories(categories);
    //   setVideos(videos);
    // }
  }, [props.match.params.video]);

  useEffect(() => {
    const { history } = props;
    window.addEventListener("popstate", (categ) => {
      return history.push('/');
    });
  }, []);

  const handleVideoReady = (e) => {
    if (e.player.isLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
    // console.log("Sss", e);
  };
  return (
    <div key={props.location.key} className="al-vid-cont">
      <NavBar
        firebase={firebase}
        history={history}
        records={videos}
        categories={categories}
      />
      {loading && (
        <Skeleton
          style={{ backgroundColor: "rgba(255,255,255,0.11)" }}
          className="react-player play-vid"
          variant="rect"
          animation="wave"
        />
      )}
      <ReactPlayer
        className="react-player play-vid"
        url={`https://www.youtube.com/watch?v=${video.videoID}`}
        playing={false}
        controls
        pip
        playsinline
        // light
        // playIcon
        width="100%"
        // height="100%"
        config={{
          youtube: {
            playerVars: { showinfo: 1 },
            // embedOptions: { modestbranding: 1, enablejsapi: 1 },
            // modestbranding: 1,
            // enablejsapi: 1,
            preload: true,
          },
        }}
        onReady={(e) => handleVideoReady(e)}
      />
      <div id="video-details">
        <div id="video-details-left">
          <span>
            <h3>{video.title}</h3>
          </span>
          <span>
            <p>{video.description}</p>
          </span>
        </div>
        <div id="video-details-right">
          <span>
            <Language />
            <p>{video.language}</p>
          </span>
          <span>
            <Theaters />
            <p>{video.category}</p>
          </span>
        </div>
      </div>
      <h2>Similar Videos</h2>
      <div className="main-video-container">
        <div className="all-video-container">
          {videos.map((vid) => (
            <div className="videog" key={vid.videoID}>
              {vid.videoID !== video.videoID && (
                <UserRecord record={vid} marginClass="m0m0" />
              )}
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Videos;
