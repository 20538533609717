import React, { useState, useEffect } from "react";
import {
  Alert,
  Form,
  FormGroup,
  Input,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { withFirebase } from "../../firebase";

const CreateMultipleCategoryModal = ({
  isOpen,
  onClose,
  categories,
  createCategories,
}) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [file, setFile] = useState(null);

  useEffect(() => {
    if (!!file) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [file]);

  const onChangeHandler = (e) => {
    console.log(e.target.files[0]);
    const newFile = e.target.files[0];
    setFile(newFile);
  };

  let fileReader;

  const readFile = () => {
    let content = fileReader.result;
    // console.log("file reader", content);
    let contents = content.split("\n");
    let records = contents
      .map((item) => {
        const [category, imageUrl] = item.trim().split("\t");
        const newObj = { category, imageUrl };
        if (!category) {
          // Add error
          return null;
        }
        return newObj;
      })
      .filter((item) => item !== null);

    createCategories(records);
  };

  const upload = (e) => {
    e.preventDefault();
    setLoading(true);
    fileReader = new FileReader();
    fileReader.onloadend = readFile;
    fileReader.readAsText(file);
  };

  return (
    <div>
      <Modal centered isOpen={isOpen} toggle={onClose}>
        <ModalHeader
          toggle={onClose}
          close={
            <button
              style={{ width: "auto", padding: "3px 7px", background: "red" }}
              onClick={onClose}
            >
              x
            </button>
          }
        >
          Create New Records
        </ModalHeader>
        <ModalBody>
          {error && <Alert color="danger">{error}</Alert>}
          <Form>
            <FormGroup>
              <Input name="file" type="file" onChange={onChangeHandler} />
            </FormGroup>
            <Button color="primary" disabled={disabled} onClick={upload}>
              Upload
            </Button>
          </Form>
          <p className="h6 text-muted">{loading && `Please wait...`}</p>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default withFirebase(CreateMultipleCategoryModal);
