import React from "react";
import ReactPlayer from "react-player";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

const VideoPlayer = ({ isOpen, onClose, record }) => {
  return (
    <div>
      <Modal
        centered
        isOpen={isOpen}
        toggle={onClose}
        contentClassName="video-model"
      >
        {/* <ModalHeader toggle={onClose}  close={
            <button
              style={{ width: "auto", padding: "3px 7px", background: "red" }}
              onClick={onClose}
            >
              x
            </button>
          }>
          Video
        </ModalHeader> */}
        {record.premiumVideo ? (
          <ModalBody style={{ padding: "0" }}>
            <ReactPlayer
              className="react-player"
              url={`${record.videoID}`}
              playing={true}
              controls={true}
              playsinline
              pip={true}
              width="100%"
              height="100%"
            />
          </ModalBody>
        ) : (
          <ModalBody style={{ padding: "0" }}>
            <ReactPlayer
              className="react-player"
              url={`https://www.youtube.com/watch?v=${record.videoID}`}
              playing={true}
              controls={true}
              pip={true}
              width="100%"
              height="100%"
            />
          </ModalBody>
        )}
      </Modal>
    </div>
  );
};

export default VideoPlayer;
