import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap";

const EditCategoryModal = ({ isOpen, onClose, editCategory, record }) => {
  // console.log("selected", record);
  const oldValue = record.category;
  const [disabled, setDisabled] = useState(true);
  const [category, setCategory] = useState(record.category);
  const [imageUrl, setImageUrl] = useState(record.imageUrl);

  useEffect(() => {
    if (!!category && !!imageUrl) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [category, imageUrl]);

  const submitHandler = (e) => {
    e.preventDefault();
    editCategory(record.id, { category, imageUrl }, oldValue, category);
  };

  return (
    <div>
      <Modal centered isOpen={isOpen} toggle={onClose}>
        <ModalHeader
          toggle={onClose}
          close={
            <button
              style={{ width: "auto", padding: "3px 7px", background: "red" }}
              onClick={onClose}
            >
              x
            </button>
          }
        >
          Update Category
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="category">Category</Label>
              <Input
                id="category"
                type="text"
                name="category"
                placeholder="Category"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              />
            </FormGroup>

            <FormGroup>
              <Label for="imageUrl">Image Url</Label>
              <Input
                id="imageUrl"
                type="text"
                name="imageUrl"
                placeholder="Image URL"
                value={imageUrl}
                onChange={(e) => setImageUrl(e.target.value)}
              />
            </FormGroup>
            <Button disabled={disabled} color="primary" onClick={submitHandler}>
              Update
            </Button>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default EditCategoryModal;
