import React, { useState, useEffect } from "react";
import { withFirebase } from "../../firebase/FirebaseContext";
import NavBar from "../Home/NavBar";
import { Spinner, Progress } from "reactstrap";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import Noty from "noty";
const UserProfile = ({ firebase, history }) => {
  const [image, setImage] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [url, setImageUrl] = useState(
    "https://i.pinimg.com/originals/51/f6/fb/51f6fb256629fc755b8870c801092942.png"
  );
  const [progress, setProgress] = useState(0);
  const [fName, setFirstName] = useState("");
  const [lName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhoneNumber] = useState("");
  const [updating, setUpdating] = useState(false);
  const [ImpageUpdating, setImageUpdating] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [isEmailVerified, setEmailVerified] = useState(false);
  const [user, setUser] = useState("");
  useEffect(() => {
    firebase.auth.onAuthStateChanged(async (userAuth) => {
      if (!userAuth) {
        history.push("/login");
      } else {
        getUserProfileImage();
        getUserProfileDetail();
        setUser(userAuth);
        if (userAuth.emailVerified) {
          setEmailVerified(true);
        }
      }
    });
  }, [user]);
  // useEffect(() => {
  //   const user = firebase.getUser();
  //   if (user.emailVerified) {
  //     setEmailVerified(true);
  //   }
  // }, []);

  const getUserProfileDetail = async () => {
    let snapShot = await firebase.getUserProfileDetails();
    const data = snapShot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    data.map((data) => {
      const { id, fName, lName, email, phone, Url } = data;
      if (id === user.uid) {
        setFirstName(fName);
        setLastName(lName);
        setEmail(email);
        setPhoneNumber(phone);
        if (Url) {
          setImageUrl(Url);
        }
      }
    });
  };
  const handleUploadImage = (e) => {
    setErrorMsg("");
    if (e.target.files[0]) {
      const imag = e.target.files[0];
      setImage(imag);
    }
  };
  const handleUpload = () => {
    if (image == null) {
      setErrorMsg("Please select image");
      return;
    }
    setImageUpdating(true);
    const uploadImage = firebase
      .getUserProfileImage()
      .ref(`users/${user.uid}/profile.jpg`)
      .put(image);
    uploadImage.on(
      "state_changed",
      (snapshot) => {
        //  progress function
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(progress);
      },
      (error) => {
        //  progress function
        console.log("error", error);
      },
      () => {
        //  complete function
        setTimeout(() => {
          new Noty({
            text: "Profile Updated Successfully",
            theme: "bootstrap-v4",
            type: "success",
          }).show();
        }, 100);
        getUserProfileImage();
        setImageUpdating(false);
      }
    );
  };
  const getUserProfileImage = () => {
    console.log("url", url);
    console.log("hjhjhj", user.uid);
    firebase
      .getUserProfileImage()
      .ref(`users/${user.uid}/profile.jpg`)
      .getDownloadURL()
      .then((url) => {
        setImageUrl(url);
      })
      .catch((error) => {
        console.log(error);
        setImageUrl(
          "https://i.pinimg.com/originals/51/f6/fb/51f6fb256629fc755b8870c801092942.png"
        );
      });
  };

  const handleProfileUpdateButton = async () => {
    if (fName.length == 0) {
      setErrorMsg("Please enter valid name");
      return;
    }
    if (lName.length == 0) {
      setErrorMsg("Please enter valid name");
      return;
    }
    if (!isValidPhoneNumber(phone)) {
      return setErrorMsg("Please enter a valid number");
    }
    setErrorMsg("");
    setDisabled(true);
    setUpdating(true);
    const newRecord = { fName, lName, phone };
    // console.log(newRecord);
    await firebase.updateProfileDetails(newRecord).then(setDisabled(false));

    setUpdating(false);
  };
  const handleVerifyEmailButton = () => {
    const res = firebase.sendEmailVerification();
    const { success, error, verified } = res;
    if (success) {
      setTimeout(() => {
        new Noty({
          text: "check your mail for further instruction",
          theme: "bootstrap-v4",
          type: "success",
        }).show();
      }, 100);
    }
    if (success === false) {
      console.log(error);
    }
    if (verified) {
      setTimeout(() => {
        new Noty({
          text: "Email is Already Verified",
          theme: "bootstrap-v4",
          type: "error",
        }).show();
      }, 100);
    }
  };
  return (
    <div>
      <NavBar firebase={firebase} history={history} />
      <section className="login">
        <div className="loginContainer profileContainer">
          <h1>Update Profile</h1>
          {ImpageUpdating && (
            <span id="profile-progress">
              <Progress multi>
                <Progress animated bar value={progress}>
                  {progress}% done...
                </Progress>
              </Progress>
            </span>
          )}
          <div className="user-profile">
            <img src={url} alt="avatar" />
            <input type="file" onChange={handleUploadImage} />
            {!ImpageUpdating && <button onClick={handleUpload}>Upload</button>}
          </div>
          <label>First Name</label>
          <input
            type="text"
            placeholder="Enter First Name"
            required
            value={fName}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <label>Last Name</label>
          <input
            type="text"
            placeholder="Enter Last Name"
            required
            value={lName}
            onChange={(e) => setLastName(e.target.value)}
          />
          <label>Email</label>
          <input
            type="email"
            placeholder="Email"
            required
            disabled
            value={email}
          />
          {isEmailVerified === false && (
            <span
              className="errorMsg"
              style={{ textAlign: "right", cursor: "pointer" }}
            >
              Verify your email
            </span>
          )}
          {isEmailVerified === false ? (
            <span
              onClick={handleVerifyEmailButton}
              style={{
                color: "red",
                cursor: "pointer",
                position: "relative",
                top: "-35px",
                left: "355px",
              }}
            >
              verify
            </span>
          ) : (
            <span
              onClick={handleVerifyEmailButton}
              style={{
                position: "relative",
                top: "-35px",
                left: "370px",
              }}
            >
              <img
                src="https://www.flaticon.com/svg/static/icons/svg/3472/3472620.svg"
                width="20px"
              />
            </span>
          )}
          <label>Mobile</label>
          <PhoneInput
            countryCallingCodeEditable
            defaultCountry="IN"
            placeholder="Enter Number"
            value={phone}
            required
            onChange={setPhoneNumber}
          />
          <p className="errorMsg">{errorMsg}</p>
          <div className="btnContainer">
            {updating ? (
              <Spinner color="success" />
            ) : (
              <button disabled={disabled} onClick={handleProfileUpdateButton}>
                Update Profile
              </button>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default withFirebase(UserProfile);
