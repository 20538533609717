import React, { useState, useEffect } from "react";
import {
  Form,
  FormGroup,
  Input,
  Label,
  Button,
  Alert,
  Spinner,
} from "reactstrap";
import { withRouter } from "react-router-dom";
import { withFirebase } from "../../firebase";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AdminLogin = ({ firebase, history }) => {
  // console.log("firebase user", firebase.user);
  const [signingIn, setSigningIn] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(true);

  // useEffect(() => {
  //   if (!!firebase.user) {
  //     history.push("/admin/content");
  //   }
  // }, []);
  useEffect(() => {
    firebase.auth.onAuthStateChanged(async (userAuth) => {
      if (userAuth) {
        history.push("/admin/content");
      } else {
        history.push("/admin/login");
      }
    });
  }, []);

  useEffect(() => {
    if (!!email && !!password) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [email, password]);

  const submitHandler = async () => {
    setDisabled(true);
    setSigningIn(true);
    const res = await firebase.signInUser(email, password);
    // console.log("res from fb instance", res);
    const { success, error } = res;
    if (success) {
      history.push("/admin/content");
    } else {
      setError(error);
      setSigningIn(false);
      setDisabled(false);
    }
  };
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="container">
      <div
        style={{
          background: "white",
          marginTop: "32px",
          padding: "16px",
          textAlign: "left",
        }}
      >
        <p className="h3">Log in</p>
        <Form>
          {error && <Alert color="danger">{error}</Alert>}
          <FormGroup>
            <Label for="email">Email</Label>
            <Input
              id="email"
              type="email"
              autoFocus
              value={email}
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="password">Password</Label>
            <Input
              id="password"
              type={showPassword ? "password" : "text"}
              value={password}
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
              onKeyPress={(event)=> event.key === "Enter" && submitHandler()}
            />
            <span
              id="showpassword"
              style={{
                float: "right",
                position: "relative",
                top: "-30px",
                right: "7px",
              }}
              onClick={handleShowPassword}
            >
              {showPassword ? (
                <FontAwesomeIcon icon={faEye} />
              ) : (
                <FontAwesomeIcon icon={faEyeSlash} />
              )}
            </span>
          </FormGroup>

          {signingIn ? (
            <span>
              <Spinner color="primary" />
              <p className="h6">Please wait...</p>
            </span>
          ) : (
            <Button color="primary" disabled={disabled} onClick={submitHandler}>
              Sign In
            </Button>
          )}
          {/* {signingIn && <p className="h6">Please wait...</p>} */}
        </Form>
      </div>
    </div>
  );
};

const Component = withFirebase(AdminLogin);

export default withRouter(Component);
