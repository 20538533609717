import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import { withFirebase } from "../../firebase";
import {
  Avatar,
  Grid,
  Paper,
  TextField,
  Button,
  Typography,
  FormControl,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { pink } from "@material-ui/core/colors";
import LockOpenOutlinedIcon from "@material-ui/icons/LockOpenOutlined";

const useStyles = makeStyles((theme) => ({
  root: {
    // display: "flex",
    // flexWrap: "wrap",
  },
  margin: {
    // margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(4),
  },
  textField: {
    // width: "25ch",
  },
  pink: {
    color: theme.palette.getContrastText(pink[500]),
    backgroundColor: pink[500],
  },
}));
const ForgotPassword = ({ firebase }) => {
  const classes = useStyles();
  const [disabled, setDisabled] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [msg, setMessage] = useState("");

  useEffect(() => {
    if (email) {
      setError("");
    }
  }, []);
  const handleForgotPassword = async () => {
    if (!new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(email)) {
      return setError("Enter valid email");
    }
    // setError("");
    setDisabled(true);
    await firebase
      .resetPassword(email)
      .then(function () {
        // Email sent.
        setDisabled(false);
        return setMessage("Check your inbox for further instructions");
      })
      .catch(function (error) {
        // An error happened.
        setDisabled(false);
        return setError(error.message);
      });
  };

  return (
    <div>
      <Grid spacing={1} className="loginGrid">
        <Paper
          elevation={10}
          style={{ marginTop: "30vh" }}
          className="loginForm"
        >
          <Grid align="center">
            <Avatar className={classes.pink}>
              <LockOpenOutlinedIcon />
            </Avatar>
            <h1>Password Reset</h1>
          </Grid>
          {error && (
            <Alert variant="filled" severity="error">
              {error}
            </Alert>
          )}
          {msg && (
            <Alert variant="filled" severity="success">
              {msg}
            </Alert>
          )}
          <FormControl
            className={clsx(
              classes.margin,
              classes.withoutLabel,
              classes.textField
            )}
            fullWidth
          >
            <TextField
              error={error}
              // helperText={error}
              autoFocus
              id="outlined-secondary"
              label="Enter Email"
              type="email"
              variant="outlined"
              placeholder="example@mail.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </FormControl>

          <FormControl
            className={clsx(
              classes.margin,
              classes.withoutLabel,
              classes.textField
            )}
            fullWidth
          >
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={disabled}
              onClick={handleForgotPassword}
            >
              Send Reset Password Link
            </Button>
          </FormControl>

          <FormControl
            className={clsx(
              classes.margin,
              classes.withoutLabel,
              classes.textField
            )}
            fullWidth
          >
            <Typography>
              <Link to="/login">Back to Login</Link>
            </Typography>
          </FormControl>
        </Paper>
      </Grid>
      {/* <div>
      <section className="login">
        <div className="loginContainer">
          <h1>Password Reset</h1>
          {error && <Alert color="danger">{error}</Alert>}
          {msg && <Alert varient="success">{msg}</Alert>}
          <label>Email</label>
          <input
            type="email"
            autoFocus
            placeholder="Enter your email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <div className="btnContainer">
            <button disabled={disabled} onClick={handleForgotPassword}>
              Reset Password
            </button>
            <div className="btnContainer">
              <Link to="/login">Back to Login</Link>
            </div>
          </div>
        </div>
      </section> */}
    </div>
  );
};
const Component = withFirebase(ForgotPassword);

export default withRouter(Component);
