import React, { useState, useEffect } from "react";
import UserRecord from "../Home/UserRecord";
import "../../assets/css/AllCategoryVideos.css";
import NavBar from "../Home/NavBar";
import "./Search.css"
const Search = (props) => {
  const [videos, setVideos] = useState(props.location.videos);
  const [searchArray, setSearchArray] = useState(props.location.searchArray);
  const [categories, setCategories] = useState(props.location.categories);
  const [searchText, setSearchText] = useState(props.location.searchText);
  const { firebase } = useState(props.location.location);
  const { history } = useState(props.location.history);
  useEffect(() => {
    if (searchText !== props.location.search) {
      window.scrollTo(0, 0);
      setSearchArray(props.history.location.searchArray);
      setSearchText(props.location.searchText);
    } else {
    }
  }, [props.location.search]);
  useEffect(() => {
    window.addEventListener("popstate", (categ) => {
      history.push("/");
      return;
    });
    // eslint-disable-next-line
  }, []);
  return (
    <div style={{ backgroundColor: "#000"}} >
      <NavBar
        firebase={firebase}
        history={history}
        records={videos}
        categories={categories}
      />
      <div style={{ marginTop: "70px" }} className='main-video-container'>
        <h2 id='cat-img-name'>
          Search Results for : <i>{searchText}</i>
        </h2>
        <div className='all-video-container'>
          {searchArray.length >= 0 &&
            searchArray.map((video) => (
              <div className='videog' key={video.videoID}>
                <UserRecord
                  record={video}
                  records={videos}
                  categories={categories}
                  marginClass='m0m0'
                />
              </div>
            ))}
          {searchText && searchArray.length <= 0 &&  <h2 style={{color: "#fff"}}>No videos found</h2>}
        </div>
      </div>
    </div>
  );
};

export default Search;
