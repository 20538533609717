import React, { useState, useEffect } from "react";
import "../../assets/css/Categories.css";
import {
  Card,
  CardBody,
  CardTitle,
  CardImg,
  // Dropdown,
  // DropdownMenu,
  // DropdownItem,
  // DropdownToggle,
} from "reactstrap";
// import {
//   faEllipsisV,
//   faEdit,
//   faTrashAlt,
// } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { withFirebase } from "../../firebase";

import EditCategoryModal from "./EditCategoryModal";
import CreateCategoryModal from "./CreateCategoryModal";
import CreateMultipleCategoryModal from "./CreateMultipleCategoryModal";

const Categories = ({ firebase, history }) => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isCreateMultipleModalOpen, setIsCreateMultipleModalOpen] = useState(
    false
  );
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selected, setSelected] = useState(null);
  // const [dropdownOpen, setDropDownOpen] = useState(false);

  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = async () => {
    let snapShot = await firebase.getCategories();
    // console.log("snapshots", snapShot);
    let data = snapShot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    // console.log("data", data);
    setCategories(data);
  };

  const styles = {
    delete: {
      position: "absolute",
      right: "20px",
      cursor: "pointer",
      zIndex: "3",
      top: "8px",
    },
    edit: {
      position: "absolute",
      left: "20px",
      cursor: "pointer",
      zIndex: "3",
      top: "8px",
    },
    category: {
      position: "relative",
      width: "70%",
      padding: "6px",
      margin: "4px auto",
      background: "#0ba199",
      color: "#fff",
    },
    link: {
      position: "absolute",
      fontSize: "1.5rem",
      left: "4px",
      color: "blue",
      cursor: "pointer",
    },
  };

  const createHandler = async (record) => {
    record.timestamp = firebase.fromSecondsToTimestamp();
    const ref = await firebase.addCategory(record);
    let newRecord = {
      ...record,
      id: ref.id,
    };
    let newRecords = [...categories, newRecord];
    newRecords.sort((a, b) => (a.timestamp <= b.timestamp ? -1 : 1));
    setCategories(newRecords);
    setIsCreateModalOpen(false);
  };

  const createMultipleHandler = async (records) => {
    let all = records.map((rec) => {
      rec.timestamp = firebase.fromSecondsToTimestamp();
      return firebase.addCategory(rec);
    });
    const ids = await Promise.all(all);
    let newRecs = records.map((rec, index) => ({
      ...rec,
      id: ids[index].id,
    }));
    let updatedRecords = [...categories, ...newRecs];
    console.log("updated recs", updatedRecords);
    setCategories(updatedRecords);
    setIsCreateMultipleModalOpen(false);
  };

  const editHandler = async (id, record, oldValue, newValue) => {
    await firebase.updateMultipleRecords(oldValue, newValue, "category");
    await firebase.updateCategory(id, record);
    let newCategories = categories.map((cat) =>
      cat.id !== id
        ? cat
        : {
            ...cat,
            category: record.category,
            imageUrl: record.imageUrl,
          }
    );
    setCategories(newCategories);
    setIsEditModalOpen(false);
  };

  const deleteHandler = async (id) => {
    if (window.confirm(`Are you sure to delete?`)) {
      await firebase.deleteCategory(id);
      let newCategories = categories.filter((category) => category.id !== id);
      setCategories(newCategories);
    }
  };

  return (
    <div>
      <p onClick={() => history.push("/admin/content")} style={styles.link}>
        Explified
      </p>
      {isCreateModalOpen && (
        <CreateCategoryModal
          isOpen={isCreateModalOpen}
          onClose={() => setIsCreateModalOpen(false)}
          createCategory={createHandler}
        />
      )}
      {isCreateMultipleModalOpen && (
        <CreateMultipleCategoryModal
          isOpen={isCreateMultipleModalOpen}
          onClose={() => setIsCreateMultipleModalOpen(false)}
          createCategories={createMultipleHandler}
        />
      )}
      {isEditModalOpen && (
        <EditCategoryModal
          isOpen={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
          record={selected}
          editCategory={editHandler}
          categories={categories}
        />
      )}
      <div id="add-category-btn" onClick={() => setIsCreateModalOpen(true)}>
        Add
      </div>
      {/* <div
        id="add-mult-category-btn"
        onClick={() => setIsCreateMultipleModalOpen(true)}
      >
        *
      </div> */}
      <div className="container">
        <h2 className="category-list-title">Categories List</h2>
        <div className="row justify-content-md-center">
          {categories.map((cat) => (
            <div key={cat.id} className="col-md-3" style={styles}>
              <i
                onClick={() => deleteHandler(cat.id)}
                style={styles.delete}
                className="fa fa-trash"
                aria-hidden="true"
              />
              <i
                onClick={() => {
                  setSelected({
                    id: cat.id,
                    category: cat.category,
                    imageUrl: cat.imageUrl,
                  });
                  setIsEditModalOpen(true);
                }}
                style={styles.edit}
                className="fa fa-pencil"
                aria-hidden="true"
              />
              <Card>
                {/* <Dropdown
                  style={{
                    position: "absolute",
                    display: "flex",
                    alignSelf: "end",
                  }}
                  isOpen={dropdownOpen}
                  toggle={() => setDropDownOpen(!dropdownOpen)}
                >
                  <DropdownToggle>
                    <FontAwesomeIcon icon={faEllipsisV} />
                  </DropdownToggle>
                  <DropdownMenu
                    style={{
                      left: "-128px",
                    }}
                  >
                    <DropdownItem
                      onClick={() => {
                        setSelected({
                          id: cat.id,
                          category: cat.category,
                          imageUrl: cat.imageUrl,
                        });
                        setIsEditModalOpen(true);
                      }}
                    >
                      <FontAwesomeIcon icon={faEdit} />
                      Edit
                    </DropdownItem>

                    <DropdownItem onClick={() => deleteHandler(cat.id)}>
                      <FontAwesomeIcon icon={faTrashAlt} /> Delete
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown> */}
                <CardImg
                  top
                  height="200px"
                  width="100%"
                  src={cat.imageUrl}
                  alt="thumbnail"
                />
                <CardBody>
                  <CardTitle className="text-center" tag="h5">
                    {cat.category}
                  </CardTitle>
                </CardBody>
              </Card>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default withFirebase(Categories);
