import React, { useState, useRef } from "react";
import UserRecord from "./UserRecord";
import "../../assets/css/UserRecords.css";
// import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
// import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Flickity from "react-flickity-component";
// const styles = {
//   arrowRight: {
//     right: "0",
//   },
//   arrowLeft: {
//     left: "0",
//   },
// };
const UserRecords = ({ records, category, categories, firebase, history }) => {
  const flickityOptions = {
    initialIndex: 1,
    cellAlign: "left",
    contain: true,
    pageDots: false,
    selectedAttraction: 0.2,
    friction: 0.8,
  };
  // const scrollListRef = useRef(null);
  // const [forwardHide, setforwardHide] = useState(true);
  // const [backWardHide, setbackwordHide] = useState(false);

  //  Handle forward scroll of video list
  // const handleForwardClick = () => {
  //   if (scrollListRef.current) {
  //     if (scrollListRef.current.scrollLeft) {
  //       setbackwordHide(true);
  //     }
  //     if (
  //       scrollListRef.current.offsetWidth + scrollListRef.current.scrollLeft ===
  //       scrollListRef.current.scrollWidth
  //     ) {
  //       setbackwordHide(true);
  //       setforwardHide(false);
  //     }

  //     scrollListRef.current.scrollBy({
  //       top: 0,
  //       left: 200,
  //       behavior: "smooth",
  //     });
  //   }
  // };
  //  Handle backword scroll of video list
  // const handleBackWardClick = () => {
  //   if (scrollListRef.current) {
  //     if (scrollListRef.current.scrollLeft) {
  //       setforwardHide(true);
  //     }
  //     if (scrollListRef.current.scrollLeft <= 200) {
  //       setbackwordHide(false);
  //       setforwardHide(true);
  //     }
  //     scrollListRef.current.scrollBy({
  //       top: 0,
  //       left: -200,
  //       behavior: "smooth",
  //     });
  //   }
  // };
  return (
    <Flickity
      className={"carousel"} // default ''
      elementType={"div"} // default 'div'
      options={flickityOptions} // takes flickity options {}
      disableImagesLoaded={false} // default false
      reloadOnUpdate // default false/
      static // default false
    >
      {records.map((record) => (
        <div key={`${record.videoID}${record.title}`}>
          {record.category === category && (
            <UserRecord
              record={record}
              records={records}
              category={category}
              categories={categories}
              firebase={firebase}
              history={history}
              marginClass="m25m15"
            />
          )}
        </div>
      ))}
    </Flickity>
    // <div className="horizontal-scrollable">
    //   <div
    //     className="row text-center hBAr"
    //     // onDragOver={handleForwardClick}
    //     ref={scrollListRef}
    //   >
    //     {backWardHide && (
    //       <span
    //         className="arrowButtons"
    //         style={styles.arrowLeft}
    //         onClick={handleBackWardClick}
    //       >
    //         <ArrowBackIosIcon />
    //       </span>
    //     )}

    //     {records.map((record) => (
    //       <div key={record.videoID}>
    //         {record.category === category && (
    //           <UserRecord record={record} categories={category} />
    //         )}
    //       </div>
    //     ))}
    //     {forwardHide && (
    //       <span
    //         className="arrowButtons"
    //         style={styles.arrowRight}
    //         onClick={handleForwardClick}
    //       >
    //         <ArrowForwardIosIcon />
    //       </span>
    //     )}
    //   </div>
    // </div>
  );
};

export default UserRecords;
