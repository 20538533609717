import React from "react";
import "../../assets/css/SideBar.css";

const SideBar = ({ categories, selectedCategory, selectCategory }) => {
	const newCategories = [{ category: "All", id: "123" }, ...categories];

	return (
		<div id="sidebar">
			<div style={{ marginTop: "16px" }}>
				{newCategories.map(({ id, category }) => (
					<div
						id={selectedCategory === category ? "selected" : ""}
						className="each-category"
						key={id}
						onClick={() => selectCategory(category)}
					>
						{category}
					</div>
				))}
			</div>
		</div>
	);
};

export default SideBar;
