import { useState, useEffect } from "react";
import axios from "axios";

export default function useGetCategories(categoryPageNumber) {
  const [isCategoryLoading, setLoading] = useState(true);
  const [isCategoryError, setError] = useState(false);
  const [categories, setCategories] = useState([]);
  const [hasMoreCategory, setHasMoreCategory] = useState(false);
  useEffect(() => {
    setLoading(true);
    setError(false);
    let cancel;
    axios({
      method: "GET",
      baseURL: "https://backend-explified.web.app/",
      // baseURL: "http://localhost:5001/explified-app/us-central1/app/",
      url: "api/v1/category/get",
      params: { page: categoryPageNumber, limit: 10 },
      cancelToken: axios.CancelToken((c) => (cancel = c)),
    })
      .then((res) => {
        setCategories((prevCategories) => {
          return [...prevCategories, ...res.data.data];
        });
        if (res.data.next) {
          setHasMoreCategory(true);
        }
        setLoading(false);
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          return;
        }
        setError(true);
        console.log(error);
      });
    return () => cancel();
  }, [categoryPageNumber]);
  return { isCategoryLoading, categories, isCategoryError, hasMoreCategory };
}
