import React, { useState, useRef, useCallback } from "react";
import { withFirebase } from "../../firebase/FirebaseContext";
import getVideos from "../API/getVideos";
import getCategories from "../API/getCategories";
import { Backdrop, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import VideoPlayer from "../VideoPlayer/VideoPlayer";
// import Skeleton from "../Skeleton/Skeleton";
import UserRecords from "./UserRecords";
import Carousel from "react-bootstrap/Carousel";
import Flickity from "react-flickity-component";

import "../../assets/css/Home.css";
import { withRouter, Link } from "react-router-dom";
import NavBar from "./NavBar";
import Footer from "./Footer";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const Home = ({ firebase, history }) => {
  const { backdrop } = useStyles();
  // const [loading, setLoading] = useState(true);
  const [categoryPageNumber, setCategoryPageNumber] = useState(1);
  const [videoPageNumber, setVideoPageNumber] = useState(1);
  const [isPlayerOpen, setIsPlayerOpen] = useState(false);
  // const [carouselVideo, setCarouselVideo] = useState("");

  const flickityOptions = {
    initialIndex: 3,
    wrapAround: true,
    cellAlign: "center",
    contain: true,
    autoPlay: "1500",
    fullScreen: true,
    selectedAttraction: 0.2,
    friction: 0.8,
  };
  // const [records, setRecords] = useState([]);
  // const [filterRecords, setFilterRecords] = useState([]);

  // const [categories, setCategories] = useState([]);
  // const [recordCategories, setRecordCategories] = useState([]);
  // const [selectedCategory, setSelectedCategory] = useState("All");
  const {
    isCategoryLoading,
    isCategoryError,
    categories,
    hasMoreCategory,
  } = getCategories(categoryPageNumber);

  const { isVideoLoading, isVideoError, videos, hasMoreVideos } = getVideos(
    videoPageNumber
  );

  const categoryObserver = useRef();
  const lastCategoryElementRef = useCallback(
    (node) => {
      if (isCategoryLoading) return;
      if (categoryObserver.current) categoryObserver.current.disconnect();

      categoryObserver.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMoreCategory) {
          setCategoryPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) categoryObserver.current.observe(node);
    },
    [isCategoryLoading, hasMoreCategory]
  );

  const videoObserver = useRef();
  const lastVideoElementRef = useCallback(
    (node) => {
      if (isCategoryLoading) return;
      if (videoObserver.current) videoObserver.current.disconnect();

      videoObserver.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMoreVideos) {
          setVideoPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) videoObserver.current.observe(node);
    },
    [isCategoryLoading, hasMoreCategory]
  );

  // console.log("home props ", props);

  // useEffect(() => {
  //   firebase.auth.onAuthStateChanged(async (userAuth) => {
  //     if (userAuth) {
  //       history.push("/");
  //     } else {
  //       history.push("/login");
  //     }
  //   });
  // }, []);
  // useEffect(() => {
  //   if (!!firebase.user) {
  //     history.push("/");
  //   }
  // }, []);

  // useEffect(() => {
  //   getCategories();
  // }, []);

  // useEffect(() => {
  //   getRecords();
  // }, []);

  // useEffect(() => {
  //   // console.log("recs", filterRecords);
  // }, [filterRecords]);

  // useEffect(() => {
  //   let newRecords = records.filter((rec) => {
  //     let isCategory = true;
  //     if (selectedCategory !== "All" && selectedCategory !== "") {
  //       isCategory = rec.category === selectedCategory;
  //     }

  //     return isCategory;
  //   });
  //   // console.log("new recs", newRecords);
  //   setFilterRecords(newRecords);
  // }, [selectedCategory]);

  // const getRecords = async (category, audio, text) => {
  //   setLoading(true);
  //   try {
  //     let snapShot = await firebase.getRecords(category, audio, text);
  //     // console.log("snapshot", snapShot);
  //     const data = snapShot.docs.map((doc) => ({
  //       ...doc.data(),
  //       id: doc.id,
  //     }));

  //     // if 0 records then loader will show on the screen
  //     if (data.length > 0) {
  //       setLoading(false);
  //     }
  //     // console.log("data", data);
  //     setRecords(data);
  //     setFilterRecords(data);
  //     // const array = new Array();

  //     // data.map(({ category: cat }) => {
  //     //   array.push(cat);
  //     //   let unique = array.filter((item, i, ar) => ar.indexOf(item) === i);
  //     //   setRecordCategories(unique);
  //     // });
  //   } catch (err) {
  //     setLoading(true);
  //     console.log(err.message);
  //   }
  // };

  // const getCategories = async () => {
  //   let snapShot = await firebase.getCategories();
  //   // console.log("snapshot", snapShot);
  //   const data = snapShot.docs.map((doc) => ({
  //     ...doc.data(),
  //     id: doc.id,
  //   }));
  //   // console.log("categories", data);
  //   setCategories(data);
  // };

  // carouse with react
  // <Carousel className="main-carousel" wrap="false" pause="hover" fade>
  //   {videos.slice(0, 8).map((video) => (
  //     <Carousel.Item
  //       key={video.videoID}
  //       className="carousel"
  //       interval={2000}
  //     >
  //       <img
  //         onClick={() => carouselClickHandler(video)}
  //         className="d-block w-100"
  //         src={
  //           video.premiumVideo
  //             ? `${video.thumbnailURL}`
  //             : `https://img.youtube.com/vi/${video.videoID}/maxresdefault.jpg`
  //         }
  //         alt="slides"
  //       />
  //       <Carousel.Caption>
  //         <h6>{video.title}</h6>

  //         {/* <p>
  //               Nulla vitae elit libero, a pharetra augue mollis interdum.
  //             </p> */}
  //       </Carousel.Caption>
  //     </Carousel.Item>
  //   ))}
  // </Carousel>;

  // const carouselClickHandler = (video) => {
  //   setCarouselVideo(video);
  //   setIsPlayerOpen(true);
  // };
  return (
    <div className="home">
      {isVideoLoading ? (
        <Backdrop className={backdrop} open={isVideoLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <div>
          {/* {isPlayerOpen && (
            <VideoPlayer
              isOpen={isPlayerOpen}
              onClose={() => setIsPlayerOpen(false)}
              record={carouselVideo}
            />
          )} */}
          <NavBar
            firebase={firebase}
            history={history}
            records={videos}
            categories={categories}
          />

          <Flickity
            className={"carousel margin-top"} // default ''
            elementType={"div"} // default 'div'
            options={flickityOptions} // takes flickity options {}
            disableImagesLoaded={false} // default false
            reloadOnUpdate // default false/
            static // default false
            id="margin-top"
          >
            {videos.slice(0, 8).map((video) => (
              <Link
                to={{
                  pathname: `/video/${video.title}`,
                  video: video,
                  firebase: firebase,
                  history: history,
                  videos: videos,
                  categories: categories,
                }}
                params={{ video: video.title }}
                id="record-more-button"
                className="img"
                key={video.videoID}
              >
                <img
                  // onClick={() => carouselClickHandler(video)}
                  src={
                    video.premiumVideo
                      ? `${video.thumbnailURL}`
                      : `https://img.youtube.com/vi/${video.videoID}/maxresdefault.jpg`
                  }
                  alt={video.title}
                />
                <div className="carousel-title">{video.title}</div>
              </Link>
            ))}
          </Flickity>

          <div className="home-content">
            {categories.map((category, index) => {
              if (category.videoCount !== 0) {
                if (categories.length === index + 1) {
                  return (
                    <div key={category.id}>
                      <span
                        ref={lastCategoryElementRef}
                        className="category-name"
                      >
                        {category.category}
                        <Link
                          to={{
                            pathname: `/category/${category.category}`,
                            videos: videos,
                            firebase: firebase,
                            history: history,
                            categoryName: category.category,
                            categoryImage: category.imageUrl,
                            categories: categories,
                          }}
                          params={{ category: category.category }}
                          id="category-more-button"
                        >
                          More
                        </Link>
                      </span>
                      <UserRecords
                        records={videos}
                        category={category.category}
                      />
                    </div>
                  );
                } else {
                  return (
                    <div key={category.id}>
                      <span className="category-name">
                        {category.category}
                        <Link
                          to={{
                            pathname: `/category/${category.category}`,
                            videos: videos,
                            firebase: firebase,
                            history: history,
                            categoryName: category.category,
                            categoryImage: category.imageUrl,
                            categories: categories,
                          }}
                          params={{ category: category.category }}
                          id="category-more-button"
                        >
                          More
                        </Link>
                      </span>
                      <UserRecords
                        records={videos}
                        category={category.category}
                        categories={categories}
                        firebase={firebase}
                        history={history}
                      />
                    </div>
                  );
                }
              }
            })}

            {isCategoryLoading && (
              <span style={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress color="secondary" />
              </span>
            )}
          </div>
          <Footer />
        </div>
      )}
    </div>
  );
};

export default withFirebase(Home);
