import React, { useState } from "react";
import {
  faEllipsisV,
  faEdit,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Card,
  CardBody,
  CardImg,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import "../../assets/css/Record.css";

import EditRecordModal from "../EditRecordModal/EditRecordModal";
import EditRecordVideoModal from "../EditRecordModal/EditRecordVideoModal";
import DeleteModal from "../DeleteModal/DeleteModal";
import VideoPlayer from "../VideoPlayer/VideoPlayer";

const Record = ({
  record,
  categories,
  onDelete,
  onUpdate,
  onVideoDelete,
  allLanguages,
  decreaseVideoCount,
  increaseVideoCount,
}) => {
  // console.log("window", window);
  // const styles = {
  //   deleteIcon: {
  //     color: "white",
  //     position: "absolute",
  //     top: "4px",
  //     right: "8px",
  //     zIndex: "3",
  //   },
  //   editIcon: {
  //     color: "white",
  //     position: "absolute",
  //     top: "4px",
  //     left: "8px",
  //     zIndex: "3",
  //   },
  // };

  const {
    id,
    title,
    category,
    videoID,
    premiumVideo,
    thumbnailURL,
    timestamp,
    duration,
  } = record;

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [isEditVideoModalOpen, setIsEditVideoModalOpen] = useState(false);
  // const [isDeleteVideoModalOpen, setIsDeleteVideoModalOpen] = useState(false);
  const [dropdownOpen, setDropDownOpen] = useState(false);

  const [isPlayerOpen, setIsPlayerOpen] = useState(false);

  const editHandler = () => {
    setIsEditModalOpen(true);
  };
  const editVideoHandler = () => {
    setIsEditVideoModalOpen(true);
  };

  const updateRecord = (record) => {
    onUpdate(id, record);
    setIsEditModalOpen(false);
    setIsEditVideoModalOpen(false);
  };

  const clickHandler = () => {
    setIsPlayerOpen(true);
  };

  const ellipsize = (str, limit) => {
    if (str.length > limit) {
      return `${str.slice(0, limit)}...`;
    }
    return str;
  };

  const convertDate = (ts) => {
    const { nanoseconds: ns, seconds: s } = ts;
    let date = new Date(s * 1000).toString().split(" ");
    let nDate = `${date[1]} ${date[2]}, ${date[3]}`;
    return nDate;
  };

  return (
    <>
      {isPlayerOpen && (
        <VideoPlayer
          isOpen={isPlayerOpen}
          onClose={() => setIsPlayerOpen(false)}
          record={record}
        />
      )}
      {isEditModalOpen && (
        <EditRecordModal
          isOpen={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
          record={record}
          categories={categories}
          updateRecord={updateRecord}
          allLanguages={allLanguages}
          decreaseVideoCount={decreaseVideoCount}
          increaseVideoCount={increaseVideoCount}
        />
      )}
      {isDeleteModalOpen && (
        <DeleteModal
          isOpen={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          record={record}
          deleteRecord={onDelete}
          deleteVideoRecord={onVideoDelete}
        />
      )}
      {isEditVideoModalOpen && (
        <EditRecordVideoModal
          isOpen={isEditVideoModalOpen}
          onClose={() => setIsEditVideoModalOpen(false)}
          record={record}
          categories={categories}
          updateRecord={updateRecord}
        />
      )}
      {/* <i
        onClick={() => setIsDeleteModalOpen(true)}
        className="fa fa-trash-o icons"
        aria-hidden="true"
        style={styles.deleteIcon}
      /> */}
      {/* {premiumVideo ? (
        <i
          onClick={editVideoHandler}
          className="fa fa-edit icons"
          aria-hidden="true"
          style={styles.editIcon}
        />
      ) : (
        <i
          onClick={editHandler}
          className="fa fa-edit icons"
          aria-hidden="true"
          style={styles.editIcon}
        />
      )} */}
      <Card className="" style={{ height: "320px" }}>
        {premiumVideo ? (
          <div>
            <CardImg
              onClick={clickHandler}
              top
              height="200px"
              width="100%"
              src={thumbnailURL}
              alt="thumbnail"
            />
            <img
              style={{
                position: "absolute",
                top: "5px",
                left: "5px",
              }}
              src="https://www.flaticon.com/svg/static/icons/svg/1458/1458256.svg"
              width="20px"
            />
          </div>
        ) : (
          <div
            style={{
              position: "relative",
            }}
          >
            <CardImg
              onClick={clickHandler}
              top
              height="200px"
              width="100%"
              src={`https://img.youtube.com/vi/${videoID}/maxresdefault.jpg`}
              alt="thumbnail"
            />
            <small
              style={{
                position: "absolute",
                bottom: "0",
                right: "0",
                backgroundColor: "white",
              }}
            >
              {duration}
            </small>
          </div>
        )}
        <Dropdown
          style={{
            position: "absolute",
            display: "flex",
            alignSelf: "flex-end",
          }}
          isOpen={dropdownOpen}
          toggle={() => setDropDownOpen(!dropdownOpen)}
        >
          <DropdownToggle
            style={{ backgroundColor: "transparent", border: "none" }}
          >
            <FontAwesomeIcon icon={faEllipsisV} />
          </DropdownToggle>
          <DropdownMenu className="dropdown-record">
            {premiumVideo ? (
              <DropdownItem onClick={editVideoHandler}>
                <FontAwesomeIcon icon={faEdit} />
                Edit
              </DropdownItem>
            ) : (
              <DropdownItem onClick={editHandler}>
                <FontAwesomeIcon icon={faEdit} />
                Edit
              </DropdownItem>
            )}
            <DropdownItem onClick={() => setIsDeleteModalOpen(true)}>
              <FontAwesomeIcon icon={faTrashAlt} /> Delete
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
        <CardBody>
          <p
            className="card-p r-title"
            style={{ fontSize: "15px", fontWeight: "600" }}
          >
            {ellipsize(title, 89)}
          </p>
          <p className="card-p" style={{}}>
            {category}
          </p>
          <small className="text-muted card-p">{convertDate(timestamp)}</small>
        </CardBody>
      </Card>
    </>
  );
};

export default Record;
