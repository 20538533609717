import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Col,
  Row,
} from "reactstrap";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
const CreateRecordModal = ({
  isOpen,
  onClose,
  addRecord,
  categories,
  allFields,
  allLanguages,
  addFieldInAllDocument,
  isVideoId,
}) => {
  const [disabled, setDisabled] = useState(true);
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [isLanguageDropDownOpen, setIsLanguageDropDownOpen] = useState(false);
  // const [isDropDownOpenTopic, setIsDropDownOpenTopic] = useState(false);
  // const [freeVideo, setFreeVideo] = useState("");
  // const [premiumVideo, setPremiumVideo] = useState("");

  const [error, setError] = useState(null);
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const [categoryID, setCategoryID] = useState("");
  const [videoId, setVideoId] = useState("");
  const [description, setDescription] = useState("");
  const [language, setLanguage] = useState("English");
  const [duration, setDuration] = useState("00:00");
  const [tag, setTag] = useState([]);
  // const languages = ["English", "Hindi"];
  const [allInputFieldsName, setAllInputFieldsName] = useState([]);
  const [inputFieldsName, setInputFieldsName] = useState([]);
  // 	const [topic, setTopic] = useState("");
  // useEffect(() => {
  //   if (title && category && videoID && topic) {
  //     setDisabled(false);
  //   } else {
  //     setDisabled(true);
  //   }
  // }, [title, category, videoID, topic]);

  // const submitHandler = (e) => {
  //   e.preventDefault();
  //   setDisabled(true);
  //   const newRecord = {
  //     title,
  //     category,
  //     videoID,
  //     topic,
  //   };
  //   addRecord(newRecord);
  // };

  useEffect(() => {
    if (title && category && tag && videoId && language) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [title, category, tag, videoId, language]);

  const submitHandler = async (e) => {
    e.preventDefault();
    setDisabled(true);
    let isVideoIdPresent = false;
    // records.map(({ videoID }) => {
    //   if (videoID === videoId) {
    //     isVideoId = true;
    //     return setError("This Video id already exists !!!");
    //   }
    // });
    await isVideoId(videoId).then(function (result) {
      isVideoIdPresent = result;
      if (!isVideoIdPresent) {
        return setError("This Video id already exists !!!");
      }
    });
    if (isVideoIdPresent) {
      // isVideoIdPresent = false;
      setError("");
      var tagArray = tag.split(",");
      const newRecord = {
        title,
        category,
        videoID: videoId,
        premiumVideo: false,
        // description,
        tags: tagArray,
        categoryID,
        language,
        duration,
      };
      if (inputFieldsName) {
        let newFields = {};
        inputFieldsName.map((field) => {
          newFields[field.fieldName] = "";
          newRecord[field.fieldName] = field.fieldValue;
        });
        addFieldInAllDocument(newFields);
      }
      addRecord({ ...newRecord, ...allInputFieldsName });
    }
  };

  const handleCategoryOption = (id, category) => {
    setCategoryID(id);
    setCategory(category);
  };

  const handleAdditionalField = (event, index) => {
    const values = [...inputFieldsName];
    values[index][event.target.name] = event.target.value;
    setInputFieldsName(values);
  };

  const handleAddNewField = () => {
    setInputFieldsName([...inputFieldsName, { fieldName: "", fieldValue: "" }]);
  };
  const handleRemoveField = (index) => {
    const values = [...inputFieldsName];
    values.splice(index, 1);
    setInputFieldsName(values);
  };

  const handleAllUpdation = (fieldName, fieldValue) => {
    let values = { ...allInputFieldsName };
    values[fieldName] = fieldValue;
    setAllInputFieldsName(values);
  };
  return (
    <Modal centered isOpen={isOpen} toggle={onClose}>
      <ModalHeader
        toggle={onClose}
        close={
          <button
            style={{ width: "auto", padding: "3px 7px", background: "red" }}
            onClick={onClose}
          >
            x
          </button>
        }
      >
        Create New Record
      </ModalHeader>
      <ModalBody style={{ height: "545px", overflowY: "scroll" }}>
        {error && <Alert color="danger">{error}</Alert>}
        <Form>
          <FormGroup>
            <Label for="title">Title</Label>
            <Input
              id="title"
              type="text"
              name="title"
              placeholder="Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </FormGroup>
          <Row form>
            <Col>
              <FormGroup>
                <Label>Category</Label>
                <Dropdown
                  size="sm"
                  isOpen={isDropDownOpen}
                  toggle={() => setIsDropDownOpen(!isDropDownOpen)}
                >
                  <DropdownToggle caret color="info">
                    {category || "Select"}
                  </DropdownToggle>
                  <DropdownMenu
                    modifiers={{
                      setMaxHeight: {
                        enabled: true,
                        order: 890,
                        fn: (data) => {
                          return {
                            ...data,
                            styles: {
                              ...data.styles,
                              overflow: "auto",
                              maxHeight: 100,
                              width: "100%",
                            },
                          };
                        },
                      },
                    }}
                  >
                    {categories.map(({ id, category }) => (
                      <span key={id}>
                        <DropdownItem
                          onClick={() => handleCategoryOption(id, category)}
                        >
                          {category}
                        </DropdownItem>
                        <DropdownItem divider />
                      </span>
                    ))}
                  </DropdownMenu>
                </Dropdown>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="language">Language</Label>
                <Dropdown
                  id="language"
                  size="sm"
                  isOpen={isLanguageDropDownOpen}
                  toggle={() =>
                    setIsLanguageDropDownOpen(!isLanguageDropDownOpen)
                  }
                >
                  <DropdownToggle caret color="warning">
                    {language || "Select"}
                  </DropdownToggle>
                  <DropdownMenu
                    modifiers={{
                      setMaxHeight: {
                        enabled: true,
                        order: 890,
                        fn: (data) => {
                          return {
                            ...data,
                            styles: {
                              ...data.styles,
                              overflow: "auto",
                              maxHeight: 100,
                              width: "100%",
                            },
                          };
                        },
                      },
                    }}
                  >
                    {allLanguages.map((language, index) => (
                      <span key={language}>
                        <DropdownItem onClick={() => setLanguage(language)}>
                          {language}
                        </DropdownItem>
                        <DropdownItem divider />
                      </span>
                    ))}

                    <FormGroup
                      style={{ textAlign: "center", fontWeight: "900" }}
                    >
                      <Label for="other">Other</Label>
                      <Input
                        id="other"
                        type="text"
                        name="language"
                        placeholder="Enter Language"
                        onChange={(e) => setLanguage(e.target.value)}
                      />
                    </FormGroup>
                  </DropdownMenu>
                </Dropdown>
              </FormGroup>
              {/* <FormGroup>
                  <Label for="language">Language</Label>
                  <Input
                    id="language"
                    type="text"
                    name="language"
                    placeholder="Enter Language"
                    value={language}
                    onChange={(e) => setLanguage(e.target.value)}
                  />
                </FormGroup> */}
            </Col>
          </Row>
          {allFields.map((item) => {
            if (
              item !== "UserID" &&
              item !== "id" &&
              item !== "categoryID" &&
              item !== "category" &&
              item !== "timestamp" &&
              item !== "premiumVideo" &&
              item !== "language" &&
              item !== "tags" &&
              item !== "duration" &&
              item !== "videoID" &&
              item !== "title"
            ) {
              return (
                <FormGroup key={item}>
                  <Label for={item}>
                    {item.charAt(0).toUpperCase() + item.slice(1)}
                  </Label>
                  <Input
                    id={item}
                    type={item === "description" ? "textarea" : typeof item}
                    name={item}
                    placeholder={item}
                    onChange={(e) => handleAllUpdation(item, e.target.value)}
                  />
                </FormGroup>
              );
            }
          })}

          {/* <FormGroup>
              <Label for="description">Description</Label>
              <Input
                id="description"
                type="textarea"
                name="description"
                placeholder="Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </FormGroup> */}
          <FormGroup>
            <Label for="duration">Duration</Label>
            <Input
              id="duration"
              type="time"
              name="duration"
              value={duration}
              onChange={(e) => setDuration(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="tags">Tags</Label>
            <Input
              id="tags"
              type="text"
              name="tags"
              placeholder="Enter tags separated by commas (,)"
              value={tag}
              onChange={(e) => setTag(e.target.value)}
            />
          </FormGroup>
          {/* NOTE: ADD TOPIC Field to video */}
          {/* <FormGroup>
								<Label>Topic</Label>
								<Dropdown
									size="sm"
									isOpen={isDropDownOpenTopic}
									toggle={() =>
										setIsDropDownOpenTopic(!isDropDownOpenTopic)
									}
								>
									<DropdownToggle caret>
										{topic || "Select"}
									</DropdownToggle>
									<DropdownMenu
										modifiers={{
											setMaxHeight: {
												enabled: true,
												order: 890,
												fn: data => {
													return {
														...data,
														styles: {
															...data.styles,
															overflow: "auto",
															maxHeight: 100
														}
													};
												}
											}
										}}
									>
										{categories.map(({ id, topic }) => (
											<DropdownItem
												key={id}
												onClick={() =>
													setTopic(topic)
												}
											>
												{topic}
											</DropdownItem>
										))}
									</DropdownMenu>
								</Dropdown>
							</FormGroup> */}
          <FormGroup>
            <Label for="videoID">Video Id</Label>
            <Input
              id="videoID"
              type="text"
              name="videoID"
              placeholder="Video Id"
              value={videoId}
              onChange={(e) => setVideoId(e.target.value)}
            />
          </FormGroup>
          <AddCircleOutlineIcon
            style={{ display: "flex", margin: "0 auto", cursor: "pointer" }}
            fontSize="large"
            onClick={() => handleAddNewField()}
          />
          {inputFieldsName.map((inputField, index) => {
            return (
              <Row key={index} form style={{ paddingTop: "5px" }}>
                <Col>
                  <FormGroup>
                    {/* <Label for="fieldName">Field Name</Label> */}
                    <Input
                      id="fieldName"
                      type="text"
                      name="fieldName"
                      placeholder="fieldName (in camel case without spacing)"
                      value={inputField.fieldName}
                      onChange={(event) => handleAdditionalField(event, index)}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    {/* <Label for="fieldValue">Field Value</Label> */}
                    <Input
                      id="fieldValue"
                      type="text"
                      placeholder="field value"
                      name="fieldValue"
                      value={inputField.fieldValue}
                      onChange={(event) => handleAdditionalField(event, index)}
                    />
                  </FormGroup>
                </Col>
                <RemoveCircleOutlineIcon
                  style={{
                    marginTop: "7px",
                    color: "red",
                    cursor: "pointer",
                  }}
                  onClick={() => handleRemoveField(index)}
                />
              </Row>
            );
          })}
          {/* <FormGroup>
              <label>Free</label>
              <FormGroup check>
                <Label check>
                  <Input
                    type="radio"
                    name="freeVideo"
                    value={freeVideo}
                    onChange={() => setFreeVideo("yes")}
                  />
                  Yes
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input
                    type="radio"
                    name="freeVideo"
                    value={freeVideo}
                    onChange={() => setFreeVideo("no")}
                  />
                  No
                </Label>
              </FormGroup>
            </FormGroup>

            <FormGroup>
              <label>Premium</label>
              <FormGroup check>
                <Label check>
                  <Input
                    type="radio"
                    name="premiumVideo"
                    value={premiumVideo}
                    onChange={() => setPremiumVideo("yes")}
                  />
                  Yes
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input
                    type="radio"
                    name="premiumVideo"
                    value={premiumVideo}
                    onChange={() => setPremiumVideo("no")}
                  />
                  No
                </Label>
              </FormGroup>
            </FormGroup> */}
          <Button disabled={disabled} color="primary" onClick={submitHandler}>
            Create
          </Button>
          <div />
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default CreateRecordModal;
