import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import PrivateRoute from "./PrivateRoute/PrivateRoute";
import Skeleton from "./Skeleton/Skeleton";
import AdminHome from "./AdminHome/AdminHome";
import AdminLogin from "./AdminLogin/AdminLogin";
import Home from "./Home/Home";
import Categories from "./Categories/Categories";
import UserSignIn from "./UserLogin/UserSignIn";
import UserSignUp from "./UserLogin/UserSignUp";
import ForgotPassword from "./UserLogin/ForgotPassword";
import UserProfile from "./Home/UserProfile";
import AllCategoryVideos from "./Home/AllCategoryVideos";
import Videos from "./Home/Videos";
import CreateRoom from "./VideoAppComponents/CreateRoom";
import Room from "./VideoAppComponents/Room";
import Page404 from "../Page404";
import Search from "./Search/Search";

const Main = () => {
  return (
    <div>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Home} />
          {/* <Route
            path="/login"
            component={(props) => <UserSignIn {...props} />}
          />
          <Route
            path="/sign-up"
            component={(props) => <UserSignUp {...props} />}
          />
          <Route
            path="/forgot-password"
            component={(props) => <ForgotPassword {...props} />}
          /> */}
          {/* <PrivateRoute exact path="/" component={Home} /> */}
          <Route
            exact
            path="/category/:category"
            component={AllCategoryVideos}
          />
          <Route exact path="/video/:video" component={Videos} />
          <PrivateRoute
            exact
            path="/admin/content/categories"
            component={Categories}
          />
          <PrivateRoute
            exact
            path="/profile"
            component={(props) => <UserProfile {...props} />}
          />
          <PrivateRoute exact path="/admin/content" component={AdminHome} />
          <Route
            exact
            path="/admin/login"
            component={(props) => <AdminLogin {...props} />}
          />
          <Route path="/search" component={Search} />
          <Route path="/create" exact component={CreateRoom} />
          <Route path="/room/:roomID" component={Room} />
          <Route exact path="/sk" component={Skeleton} />
          <Route component={Page404} />
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default Main;
