import { useState, useEffect } from "react";
import axios from "axios";

export default function useGetVideos(videoPageNumber) {
  const [isVideoLoading, setLoading] = useState(true);
  const [isVideoError, setError] = useState(false);
  const [videos, setVideos] = useState([]);
  const [hasMoreVideos, setHasMoreVideos] = useState(false);
  useEffect(() => {
    setLoading(true);
    setError(false);
    let cancel;
    axios({
      method: "GET",
      baseURL: "https://backend-explified.web.app/",
      //baseURL: "http://localhost:5001/explified-app/us-central1/app/",
      url: "api/v1/video/get",
      // params: { page: videoPageNumber, limit: 200 },
      cancelToken: axios.CancelToken((c) => (cancel = c)),
    })
      .then((res) => {
        setVideos((prevVideos) => {
          return [...prevVideos, ...res.data.data];
        });
        if (res.data.next) {
          setHasMoreVideos(true);
        }
        setLoading(false);
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          return;
        }
        setError(true);
        console.log(error);
      });
    return () => cancel();
  }, [videoPageNumber]);
  return { isVideoLoading, isVideoError, videos, hasMoreVideos };
}
