import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Progress,
  Row,
  Col,
} from "reactstrap";

import ReactPlayer from "react-player";
const CreateRecordVideo = ({
  isOpen,
  onClose,
  addRecord,
  categories,
  firebaseStorage,
  UserID,
}) => {
  // const [disabled, setDisabled] = useState(true);

  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [isLanguageDropDownOpen, setIsLanguageDropDownOpen] = useState(false);

  const [error, setErrorMsg] = useState(null);
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const [categoryID, setCategoryID] = useState("");
  const [language, setLanguage] = useState("");
  const [duration, setDuration] = useState("00:00");
  const [description, setDescription] = useState("");
  const [tag, setTag] = useState([]);
  const languages = ["English", "Hindi"];

  const [thumbnail, setThumbnail] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [videoURL, setVideoUrl] = useState("");
  const [video, setVideo] = useState(null);
  const [thumbnailURL, setThumbnailUrl] = useState("");
  const [progress, setProgress] = useState(0);
  // const [updating, setUpdating] = useState(false);
  const [videoUpdating, setVideoUpdating] = useState(false);
  const [thumbnailUpdating, setThumbnailUpdating] = useState(false);

  useEffect(() => {
    if (title && category && description && tag && language) {
      setDisabled(false);
    } else {
      //   setError("please enter all the fields");
      setDisabled(true);
    }
  }, [title, category, description, tag, language]);

  useEffect(() => {
    getUserVideos();
  }, []);
  useEffect(() => {
    getUserVideosThumbnail();
  }, []);

  //   Video Upload function

  const handleUploadVideo = (e) => {
    setErrorMsg("");
    if (e.target.files[0]) {
      const video = e.target.files[0];
      setVideo(video);
    }
  };
  const handleUpload = () => {
    if (video == null) {
      setErrorMsg("Please select video");
      return;
    }
    setVideoUpdating(true);
    const uploadVideo = firebaseStorage
      .ref(`users/${UserID}/videos/${title}`)
      .put(video);
    uploadVideo.on(
      "state_changed",
      (snapshot) => {
        //  progress function
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(progress);
      },
      (error) => {
        //  progress function
        setErrorMsg(error.message);
        console.log("error", error);
      },
      () => {
        //  complete function
        getUserVideos();
        setVideoUpdating(false);
      }
    );
  };

  const getUserVideos = () => {
    if (video == null) {
      return;
    }
    firebaseStorage
      .ref(`users/${UserID}/videos/${title}`)
      .getDownloadURL()
      .then((url) => {
        setVideoUrl(url);
      })
      .catch((error) => {
        console.log(error);
        setVideoUrl(
          "https://i.pinimg.com/originals/51/f6/fb/51f6fb256629fc755b8870c801092942.png"
        );
      });
  };

  //thumbnail upload function

  const handleThumbnailVideo = (e) => {
    setErrorMsg("");
    if (e.target.files[0]) {
      const thumb = e.target.files[0];
      setThumbnail(thumb);
    }
  };
  const thumbnailUpload = () => {
    if (thumbnail == null) {
      setErrorMsg("Please select image");
      return;
    }
    setThumbnailUpdating(true);
    const uploadThumbnail = firebaseStorage
      .ref(`users/${UserID}/thumbnails/${title}`)
      .put(thumbnail);
    uploadThumbnail.on(
      "state_changed",
      (snapshot) => {
        //  progress function
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(progress);
      },
      (error) => {
        //  progress function
        console.log("error", error);
      },
      () => {
        //  complete function
        getUserVideosThumbnail();
        setThumbnailUpdating(false);
      }
    );
  };
  const getUserVideosThumbnail = () => {
    if (thumbnail == null) {
      return;
    }
    firebaseStorage
      .ref(`users/${UserID}/thumbnails/${title}`)
      .getDownloadURL()
      .then((url) => {
        setThumbnailUrl(url);
      })
      .catch((error) => {
        console.log(error);
        setThumbnailUrl(
          "https://i.pinimg.com/originals/51/f6/fb/51f6fb256629fc755b8870c801092942.png"
        );
      });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setDisabled(true);
    var tagArray = tag.split(",");
    const newRecord = {
      title,
      category,
      videoID: videoURL,
      thumbnailURL: thumbnailURL,
      premiumVideo: true,
      UserID: UserID,
      description,
      tags: tagArray,
      categoryID,
      language,
      duration,
    };
    addRecord(newRecord);
  };
  const handleCategoryOption = (id, category) => {
    setCategoryID(id);
    setCategory(category);
  };

  return (
    <div>
      <Modal centered isOpen={isOpen} toggle={onClose}>
        <ModalHeader
          toggle={onClose}
          close={
            <button
              style={{ width: "auto", padding: "3px 7px", background: "red" }}
              onClick={onClose}
            >
              x
            </button>
          }
        >
          Create New Record
        </ModalHeader>
        <ModalBody style={{ height: "560px", overflowY: "scroll" }}>
          {thumbnailUpdating && (
            <span className="thumbnail-progress">
              <Progress multi>
                <Progress animated bar value={progress}>
                  {progress}% done...
                </Progress>
              </Progress>
            </span>
          )}
          {videoUpdating && (
            <span className="video-progress">
              <Progress multi>
                <Progress animated bar value={progress}>
                  {progress}% done...
                </Progress>
              </Progress>
            </span>
          )}
          {error && <Alert color="danger">{error}</Alert>}

          <Form>
            <FormGroup>
              <Label for="title">Title</Label>
              <Input
                id="title"
                type="text"
                name="title"
                placeholder="Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </FormGroup>
            <Row form>
              <Col>
                <FormGroup>
                  <Label>Category</Label>
                  <Dropdown
                    size="sm"
                    isOpen={isDropDownOpen}
                    toggle={() => setIsDropDownOpen(!isDropDownOpen)}
                  >
                    <DropdownToggle caret color="info">
                      {category || "Select"}
                    </DropdownToggle>
                    <DropdownMenu
                      modifiers={{
                        setMaxHeight: {
                          enabled: true,
                          order: 890,
                          fn: (data) => {
                            return {
                              ...data,
                              styles: {
                                ...data.styles,
                                overflow: "auto",
                                maxHeight: 100,
                                width: "100%",
                              },
                            };
                          },
                        },
                      }}
                    >
                      {categories.map(({ id, category }) => (
                        <DropdownItem
                          key={id}
                          onClick={() => handleCategoryOption(id, category)}
                        >
                          {category}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </Dropdown>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label for="language">Language</Label>
                  <Dropdown
                    id="language"
                    size="sm"
                    isOpen={isLanguageDropDownOpen}
                    toggle={() =>
                      setIsLanguageDropDownOpen(!isLanguageDropDownOpen)
                    }
                  >
                    <DropdownToggle caret color="warning">
                      {language || "Select"}
                    </DropdownToggle>
                    <DropdownMenu
                      modifiers={{
                        setMaxHeight: {
                          enabled: true,
                          order: 890,
                          fn: (data) => {
                            return {
                              ...data,
                              styles: {
                                ...data.styles,
                                overflow: "auto",
                                maxHeight: 100,
                                width: "100%",
                              },
                            };
                          },
                        },
                      }}
                    >
                      {languages.map((language) => (
                        <DropdownItem
                          key={language}
                          onClick={() => setLanguage(language)}
                        >
                          {language}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </Dropdown>
                </FormGroup>
              </Col>
            </Row>
            <FormGroup>
              <Label for="description">Description</Label>
              <Input
                id="description"
                type="textarea"
                name="description"
                placeholder="Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="duration">Duration</Label>
              <Input
                id="duration"
                type="time"
                name="duration"
                value={duration}
                onChange={(e) => setDuration(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="tags">Tags</Label>
              <Input
                id="tags"
                type="text"
                name="tags"
                placeholder="Enter tags separated by commas (,)"
                value={tag}
                onChange={(e) => setTag(e.target.value)}
              />
            </FormGroup>

            {videoURL && (
              <FormGroup>
                <ReactPlayer
                  className="react-player"
                  url={videoURL}
                  controls={true}
                  width="100%"
                  height="100%"
                />
              </FormGroup>
            )}

            <FormGroup>
              <Label for="video">Video</Label>
              <span style={{ display: "flex" }}>
                <Input id="video" type="file" onChange={handleUploadVideo} />
                {!videoUpdating && (
                  <Button
                    disabled={disabled}
                    color="primary"
                    onClick={handleUpload}
                  >
                    Upload Video
                  </Button>
                )}
              </span>
            </FormGroup>
            {thumbnailURL && (
              <FormGroup>
                <img
                  id="modal-thumbnail"
                  src={thumbnailURL}
                  alt="thumbnail"
                  height="15vh !important"
                />
              </FormGroup>
            )}

            <FormGroup>
              <Label for="thumbnail">Thumbnail</Label>
              <span style={{ display: "flex" }}>
                <Input
                  id="thumbnail"
                  type="file"
                  onChange={handleThumbnailVideo}
                />
                {!thumbnailUpdating && (
                  <Button
                    disabled={disabled}
                    color="primary"
                    onClick={thumbnailUpload}
                  >
                    Upload Thumbnail
                  </Button>
                )}
              </span>
            </FormGroup>
            <Button disabled={disabled} color="info" onClick={submitHandler}>
              Create
            </Button>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default CreateRecordVideo;
